import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components/macro';
import { colors, Icons, Button } from '@united-talent-agency/components';

const { XCloseIcon } = Icons;

export const BulkDeleteModal = ({ isOpen, onCancel, onConfirm, impactedCount }) => {
  const onRequestClose = () => {
    onCancel();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={modalStyles}>
      <div>
        <ButtonContainer>
          <XCloseIcon
            inline
            onClick={onCancel}
            height={23}
            width={23}
            style={{ cursor: 'pointer', marginRight: -7 }}
          />
        </ButtonContainer>

        <ModalTitle>Bulk Delete Confirmation</ModalTitle>

        <ConfirmationText>
          Delete <BoldSpan>{impactedCount}</BoldSpan> record{impactedCount > 1 ? 's' : ''}?:
        </ConfirmationText>

        <SaveButtonContainer>
          <Button small onClick={() => onConfirm && onConfirm()} text="Confirm" />
        </SaveButtonContainer>
      </div>
    </Modal>
  );
};

const BoldSpan = styled.span({
  fontWeight: 700,
});

const ButtonContainer = styled.div({
  position: 'absolute',
  right: 15,
});

const ConfirmationText = styled.p({
  margin: 30,
  textAlign: 'center',
  fontSize: 14,
});

const SaveButtonContainer = styled.div({
  margin: '0 38px 12px 0',
  justifyContent: 'flex-end',
  display: 'flex',
  position: 'relative',
});

const ModalTitle = styled.div({
  display: 'flex',
  justifyContent: 'center',
  fontSize: 24,
  fontWeight: 300,
});

const modalStyles = {
  content: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    border: '0.5px solid rgba(0, 0, 0, 0.2)',
    background: `${colors.background} none repeat scroll 0% 0%`,
    borderRadius: 2,
    outline: 'currentcolor none medium',
    width: 454,
    overflow: 'unset',
    height: 'min-content',
    padding: '20px 0',
  },
};
