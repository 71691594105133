import React from 'react';
import styled from 'styled-components/macro';

import { Spinner, Icons, colors } from '@united-talent-agency/components';
const { ChevronDown } = Icons;

const LoadMore = ({ loading = false, onClick }) => {
  return (
    <Button onClick={onClick} disabled={loading}>
      <Prefix>{loading && <Spinner size={15} />}</Prefix>
      <Label>Load more calls</Label>
      <Suffix>
        <ChevronDown />
      </Suffix>
    </Button>
  );
};

const Button = styled.button`
   {
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    font-size: 15px;
    font-weight: bold;
    color: ${colors.utaBlack};
    background-color: ${colors.focusGrey};

    &:focus {
      outline: none;
    }

    &:active {
      background-color: ${colors.activeGrey};
    }
  }
`;

const Label = styled.span`
   {
    margin: 0 8px;
  }
`;

const Prefix = styled.div`
   {
    width: 15px;
    padding-top: 4px;
  }
`;

const Suffix = styled.div`
   {
    width: 15px;
    padding-bottom: 2px;
  }
`;

export default LoadMore;
