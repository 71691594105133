import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import {
  ColorPicker,
  ThemedInput,
  Icons,
  colors,
  SingleSelectDropdown,
  ConfirmModal,
  Tooltip,
} from '@united-talent-agency/components';
import { HEADER } from '../../support/cypressTags';
import { countStatusCalls } from '../../api/statuses';

const { GripIcon } = Icons;
const { XCloseIcon } = Icons;

const DragHandle = SortableHandle(({ cypressTag, onMouseDown, onMouseUp }) => (
  <div
    data-cy={cypressTag}
    onMouseDown={onMouseDown}
    onMouseUp={onMouseUp}
    onMouseLeave={onMouseUp}
    style={{ cursor: 'grab', alignItems: 'center', display: 'flex', marginRight: 8 }}
  >
    <GripIcon inline width={20} />
  </div>
));

const StatusItem = SortableElement(
  ({
    desk,
    status = {},
    updateColor,
    updateName,
    updateAbbr,
    updateType,
    errorState,
    removeStatus,
  }) => {
    const [dragging, setDragging] = useState(false);
    const [hasCalls, setHasCalls] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const { featureGraphql = false } = useFlags();

    const anyFieldFilled = status.name || status.color || status.type || status.status;
    const statusRowSelectItems = [
      { value: 'Initiate', label: 'Initiate' },
      { value: 'On Going', label: 'On Going' },
      { value: 'Close', label: 'Close' },
    ];

    useEffect(() => {
      if (!featureGraphql && desk && status) {
        countStatusCalls(desk._id, status.status)
          .then(({ count }) => {
            setHasCalls(count > 0);
          })
          .catch((err) => {
            console.log('error', err);
          });
      }
    }, [desk, status, featureGraphql]);

    const showConfirmModal = () => {
      if ((featureGraphql && !status.hasCallRecord) || (!featureGraphql && !hasCalls)) {
        setShowDeleteModal(true);
      }
    };

    const doRemoveStatus = () => {
      removeStatus();
      setShowDeleteModal(false);
    };

    return (
      <>
        <StatusRowListItem dragging={dragging}>
          <DragHandle
            cypressTag={HEADER.STATUS_DRAG_HANDLE}
            onMouseDown={() => setDragging(true)}
            onMouseUp={() => setDragging(false)}
          />
          <div
            data-cy={HEADER.SETTINGS.MODAL_STATUS_TEXT_BOX}
            style={{ width: 134, marginRight: 4 }}
          >
            <ThemedInput
              required={anyFieldFilled || errorState}
              title="Status"
              value={status.name}
              onChange={(e) => {
                updateName(e.target.value);
              }}
              error={errorState && !status.name}
            />
          </div>
          <div data-cy={HEADER.STATUS_ABBR} style={{ width: 52, marginRight: 4 }}>
            <ThemedInput
              required={anyFieldFilled || errorState}
              title="Initial"
              value={status.status}
              maxLength={2}
              onChange={(e) => {
                updateAbbr(e.target.value);
              }}
              error={errorState && !status.status}
            />
          </div>
          <ColorPicker
            required={anyFieldFilled || errorState}
            value={status.color || ''}
            onChange={(color) => {
              updateColor(color.hex);
            }}
            error={errorState && !status.color}
            top={'20%'}
            left={'25%'}
          />
          <div style={{ width: 134, margin: '0 4px' }}>
            <SingleSelectDropdown
              required={anyFieldFilled || errorState}
              title={'Type'}
              value={status.type && { value: status.type, label: status.type }}
              options={statusRowSelectItems}
              onChange={({ value }) => updateType(value)}
              error={errorState && !status.type}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {(featureGraphql && status.hasCallRecord) || (!featureGraphql && hasCalls) ? (
              <Tooltip
                text="Reassign all calls with this status in order to delete it (includes hidden future calls)"
                width="250px"
              >
                <XCloseIcon
                  style={{ cursor: 'pointer' }}
                  color={colors.disabledGrey}
                  inline
                  width={18}
                  height={18}
                  onClick={showConfirmModal}
                />
              </Tooltip>
            ) : (
              <XCloseIcon
                style={{ cursor: 'pointer' }}
                color={colors.red}
                inline
                width={18}
                height={18}
                onClick={showConfirmModal}
              />
            )}
          </div>
        </StatusRowListItem>
        {errorState && <ErrorMessage>All fields are required</ErrorMessage>}

        <ConfirmModal
          title="Delete Confirmation"
          message="Are you sure you want to permanently delete this status?"
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          isOpen={showDeleteModal}
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={doRemoveStatus}
        />
      </>
    );
  }
);

const StatusRowListItem = styled.li(({ dragging }) => ({
  display: 'flex',
  flexDirection: 'row',
  flex: '0 1 100%',
  marginBottom: 8,
  backgroundColor: dragging && colors.focusGrey,
  padding: '0 15px',
}));

const ErrorMessage = styled.p({
  fontSize: 12,
  margin: '-3px 46px 9px',
  color: 'red',
});

export default StatusItem;
