import React from 'react';
import Modal from 'react-modal';
import { styled, helpers } from 'react-free-style';
import classnames from 'classnames';
import * as elements from '../styles/elements';

const DeleteCallModal = (props) => {
  const { isOpen, onCancel, onConfirm, styles, cypressTag } = props;
  return (
    <Modal isOpen={isOpen} onRequestClose={onCancel} className={classnames(styles.content)}>
      <div className={styles.container} data-cy={cypressTag}>
        <h1>Delete call?</h1>
        <button
          id="confirm-delete-call"
          className={classnames(styles.confirmButton)}
          onClick={() => {
            onConfirm && onConfirm();
          }}
        >
          Confirm
        </button>
        <button
          className={classnames(styles.cancelButton)}
          onClick={() => {
            onCancel && onCancel();
          }}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};
const withStyles = styled({
  content: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    marginTop: '-50px',
    marginLeft: '-50px',
    width: '300px',
    //marginRight: '-50%',
    //transform: 'translate(-50%, -50%)',
    backgroundColor: '#333',
    overflow: 'hidden',
    boxShadow: '20px 20px 20px #bbb',
    border: '1px solid #141414',
  },
  container: {
    textAlign: 'center',
    width: '300px',
    background: 'white',
    opacity: 1,
    padding: '40px',
    boxShadow: '0 20px 75px rgba(0, 0, 0, 0.23)',
    color: '#4a4a4a',
    border: '1px solid #4a4a4a',
  },
  cancelButton: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#000',
    margin: '5px',
  }),
  confirmButton: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#cb2431',
    textTransform: 'uppercase',
    borderColor: '#000',
    margin: '5px',
  }),
});
export default withStyles(DeleteCallModal);
