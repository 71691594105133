import React from 'react';
import StatusRow from './StatusRow';
import { SortableContainer } from 'react-sortable-hoc';
import { useFlags } from 'launchdarkly-react-client-sdk';

const StatusList = SortableContainer(
  ({ desk, statuses, updateStatusField, removeStatus, errorState }) => {
    const { featureGraphql = false } = useFlags();

    return (
      <ul style={{ padding: 0, listStyle: 'none' }}>
        {/*{(statuses : []).map((status, index) => (   TODO - [ONX-170] fix how statuses are mapped into StatusList*/}
        {(Array.isArray(statuses) ? statuses : [])
          .filter((status) => (featureGraphql ? !status.remove : true))
          .map((status, index) => (
            <StatusRow
              key={`item-${index}`}
              index={index}
              removeStatus={() => removeStatus(index)}
              desk={desk}
              status={status}
              updateColor={(color) => {
                updateStatusField('color', color, index);
              }}
              updateName={(name) => {
                updateStatusField('name', name, index);
              }}
              updateAbbr={(abbr) => {
                updateStatusField('status', abbr, index);
              }}
              updateType={(type) => {
                updateStatusField('type', type, index);
              }}
              errorState={
                errorState && (!status.name || !status.color || !status.status || !status.type)
              }
            />
          ))}
      </ul>
    );
  }
);

export default StatusList;
