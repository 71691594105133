import { get, put } from './index.js';
import client from '../graphql/client.js';
import { UPDATE_STATUS, DELETE_STATUS } from '../graphql/mutations.js';
import { DESK_STATUS } from '../graphql/queries.js';

export const getStatuses = async (id, featureGraphql) => {
  if (featureGraphql) {
    const {
      data: { desks },
    } = await client.query({
      query: DESK_STATUS,
      variables: {
        where: {
          _id: id,
        },
      },
    });

    const { statuses } = desks[0];

    return statuses.map((status) => ({ ...status }));
  } else {
    const response = await get(`/v2/desks/${id}/statuses/`);
    return response.body;
  }
};

export const updateStatuses = async (id, payload, featureGraphql) => {
  if (featureGraphql) {
    const formattedStatus = payload.map((status) => ({
      color: status.color,
      deskId: status.deskId,
      name: status.name,
      order: status.order,
      remove: status.remove,
      status: status.status,
      type: status.type,
      _id: status._id,
    }));

    const statusToRemove = formattedStatus.filter((status) => status.remove);

    if (statusToRemove.length) {
      const {
        data: {
          deleteStatus: { message, success },
        },
      } = await client.mutate({
        mutation: DELETE_STATUS,
        variables: { statuses: statusToRemove },
      });

      if (!success) {
        throw new Error(message);
      }
    }

    const statusToKeep = formattedStatus.filter((status) => !status.remove);

    if (statusToKeep.length) {
      const {
        data: {
          updateStatus: { message, success },
        },
      } = await client.mutate({
        mutation: UPDATE_STATUS,
        variables: { input: statusToKeep },
      });

      if (!success) {
        throw new Error(message);
      }
    }

    return payload.filter((status) => !status.remove);
  } else {
    const response = await put(`/v2/desks/${id}/statuses/`, payload);
    if (response.status === 400) {
      throw Error(response.body.error);
    }

    return response.body;
  }
};

export const countStatusCalls = async (deskId, status) => {
  const response = await get(`/v2/desks/${deskId}/statuses/${status}`);
  return response.body;
};
