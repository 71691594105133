/**
 * Single registry for all data-cy tags used within phone-sheet & consumed dependencies
 */
module.exports = {
  FILTERS: {
    FILTERS_LIST: 'filters-list',
    STATUS_CHECKBOX: 'filter-checkbox',
    FILTER_PILL: 'filter-pill',
    SIDE_PANEL_PILL: 'side-panel-pill',
    STAR_CHECKBOX: 'star-checkbox',
  },
  SEARCH: {
    SEARCH_BAR: 'search-bar',
  },
  CALL_LIST: {
    CALL_LIST: 'call-list',
    ADD_CALL: 'add-call',
    DELETE_CALL_MODAL: 'delete-call-modal',
  },
  CALL_ROW: {
    CALL_ROW: 'call-row',
    STAR: 'call-row-star',
    NO_STAR: 'call-row-no-star',
    STATUS: 'call-row-status',
    CONTACT: 'call-row-contact',
    CONTACT_OPTIONS: 'call-row-contact-options',
    COMPANY: 'call-row-company',
    NOTES: 'call-row-notes',
    NOTES_INPUT: 'call-row-notes-input',
    DATE: 'call-row-date',
    DATE_DAY_BUTTON: 'call-row-date-day-button',
    DATE_INNER_LABEL: 'call-row-date-inner-label',
    DELETE_CALL: 'call-row-delete-call',
  },
  CALL_FORM: {
    CALL_FORM: 'call-form',
    STATUS_TEXT_BOX: 'call-form-status-text-box',
    NAME_TEXT_BOX: 'call-form-name-text-box',
    TYPE_TEXT_BOX: 'call-form-type-text-box',
    COUNTRY_TEXT_BOX: 'call-form-country-text-box',
    PHONE_TEXT_BOX: 'call-form-phone-text-box',
    EXTENSION_TEXT_BOX: 'call-form-extension-text-box',
    DATE_TEXT_BOX: 'call-form-date-text-box',
    NOTES_TEXT_BOX: 'call-form-notes-text-box',
    CANCEL_BUTTON: 'call-form-cancel-button',
    ADD_TODO_BUTTON: 'call-form-add-button',
    CHECKMARK_BUTTON: 'call-form-checkmark-button',
  },
  HEADER: {
    MENU_DROPDOWN: 'menu-dropdown',
    MENU_ITEM: 'menu-item',
    STATUS_ABBR: 'menu-status-abbr',
    STATUS_DRAG_HANDLE: 'status-dragHandle',
    SETTINGS: {
      MODAL_SAVE: 'settings-modal-save',
      MODAL_STATUS_TEXT_BOX: 'settings-status-text-box',
      MODAL_CLOSE: 'settings-modal-close',
    },
  },
  CONTACT_INPUT: {
    EXISTING_CONTACT: 'call-row-existing-contact',
    ADD_CONTACT: 'call-row-add-contact',
    CONTACT_TYPE: 'call-row-contact-type',
    PHONE_NUMBER_INPUT: 'phone-number-input',
  },
};
