import React, { useState, useEffect, useRef } from 'react';
import {
  colors,
  ContactType,
  ContactTypeSelector,
  ContactInput,
} from '@united-talent-agency/components';
import {
  existingContactNotPhone,
  existingContactPhone,
  formatContactForDisplay,
} from '../../support/contact';
import PhoneNumber from 'awesome-phonenumber';
import { isValidPhoneNumber, AsYouType, getExampleNumber } from 'libphonenumber-js';
import examples from 'libphonenumber-js/mobile/examples';
import styled from 'styled-components/macro';
import { toUnixTime } from '../../support/date';
import { getPrivateContacts } from '../../api/people';
import EllipsisText from './EllipsisText';
import { CALL_ROW, CONTACT_INPUT } from '../../support/cypressTags';

/**
 * Inline editable contact
 * contact value with type icon is displayed, with single click
 * allowing an existing contact for a master-data-person, or a desk (personal) contact
 * to be selected for replacement OR if Add Contact is chosen
 * a new contact will be created and selected for replacement
 * @param {*} props (contact, onCancel, recipient, deskAddressBookEntryContacts = [], communicationRecord, onSave)
 * @returns value of the contact, or editing components
 */
export const InlineEditableContact = (props) => {
  const {
    callTodo = {},
    recipient,
    deskAddressBookEntryContacts = [],
    onSave,
    canEdit = true,
  } = props;
  const { contact } = callTodo;
  const [createNewContact, setCreateNewContact] = useState(false);
  const [innerContact, setInnerContact] = useState(contact);
  const [editContact, setEditContact] = useState(false);
  const [contactInputError, setContactInputError] = useState(null);
  const [privateContacts, setPrivateContacts] = useState([]);

  const newContactType = useRef('');
  const newContactValue = useRef('');

  // this effect fetches the private contacts once edit-mode "editContact" is true
  useEffect(() => {
    if (editContact && recipient?._id) {
      getPrivateContacts(recipient._id).then((response) => {
        setPrivateContacts(response?.body?.privateContacts || []);
      });
    }
  }, [editContact, recipient]);

  // this sets the inner contact on load
  useEffect(() => {
    if (typeof contact !== 'undefined' && contact !== innerContact) {
      setInnerContact(contact);
    }
  }, [contact, callTodo, innerContact]);

  const formattedContact = formatContactForDisplay(innerContact);

  //combine "master data" public contacts with "master data" private contacts (that their desk has access to)
  const masterDataContacts = recipient ? (recipient.contacts || []).concat(privateContacts) : [];

  //combine masterDataContacts with desk address book entries (just fall-through logic, should never be both?)
  const existingContacts = masterDataContacts
    .concat(deskAddressBookEntryContacts)
    .map(({ _id, contactType = 'Unknown', contact = '', primary }) => ({
      _id,
      contactType,
      value: contact,
      primary,
      private: contactType && !contact,
    }));

  const phoneErrorMessage = () => {
    const asYouType = new AsYouType('ZZ');
    asYouType.input(newContactValue.current || '');

    const selectedCountry = PhoneNumber.getRegionCodeForCountryCode(asYouType.getCallingCode());
    let userRegion = 'US';
    try {
      userRegion = navigator.language.split('-')[1] || 'US';
    } catch {
      // could not get browser language
    }
    const format = selectedCountry === userRegion ? 'NATIONAL' : 'INTERNATIONAL';
    const phoneExamples = getExampleNumber(selectedCountry || userRegion, examples);
    let examplesString = '';
    if (phoneExamples) {
      examplesString = phoneExamples.format(format);
    }
    return 'Please enter a valid number, `' + examplesString + '`';
  };

  const contactAddable =
    recipient && recipient?._id?.length > 0 && recipient?.type !== 'Employee' && canEdit;
  let tooltipMessage = undefined;
  if (callTodo.recipientId?.type === 'Employee') {
    tooltipMessage = 'Contact information for an employee can only be edited (by them) in Workday.';
  } else if (callTodo.recipientId?.type === 'Client') {
    tooltipMessage = 'Please contact a client team member to make changes to this client profile.';
  } else {
    tooltipMessage = 'Phonesheet cannot edit Outlook (O) or Personal (P) contacts.';
  }

  return (
    <TableCell>
      {!createNewContact && (
        <>
          <ContactDisplayContainer
            id="contact-container"
            onClick={() => {
              setEditContact(true);
            }}
            editContact={editContact}
          >
            <span style={{ display: 'inline-block', margin: '3px 2px 0 0' }}>
              <ContactType type={formattedContact.contactType} inline />
            </span>
            <span
              data-cy={CALL_ROW.CONTACT}
              style={{ display: 'inline-block', maxWidth: 'calc(100% - 15px)' }}
            >
              <EllipsisText text={formattedContact.contact} />
            </span>
          </ContactDisplayContainer>
          {editContact && (
            <div>
              <ContactTypeSelector
                value={{
                  contactType: innerContact?.contactType || 'Unknown',
                  value: innerContact?.contact,
                }}
                existing={existingContacts}
                dropdownOnly
                isOpen={editContact}
                onClose={() => setEditContact(false)}
                // hide the 'add button' if Outlook contact (no recipient)
                // TODO: desk contacts also have no recipient and will get caught in this filter
                addable={contactAddable}
                tooltipMessage={tooltipMessage}
                cypressTags={{
                  existingContact: CONTACT_INPUT.EXISTING_CONTACT,
                  addContact: CONTACT_INPUT.ADD_CONTACT,
                  contactType: CONTACT_INPUT.CONTACT_TYPE,
                }}
                onChange={(selected) => {
                  if (!selected.value) {
                    newContactType.current = selected.contactType;
                    setCreateNewContact(true);
                  } else {
                    handleExistingContactSelected(selected, callTodo, recipient, onSave);
                  }
                  setEditContact(false);
                }}
              />
            </div>
          )}
        </>
      )}
      {createNewContact && (
        <ContactInputContainer>
          <ContactInput
            inline
            focused
            contact={{ contact: newContactValue.current, contactType: newContactType.current }}
            onChange={({ contactType, contact }) => {
              newContactType.current = contactType;
              newContactValue.current = contact;
              setContactInputError(null);
            }}
            onCancel={() => {
              setCreateNewContact(false);
              setInnerContact(contact);
              newContactValue.current = '';
              setContactInputError(null);
            }}
            onBlur={() => {
              if (!newContactValue.current) {
                return;
              }

              const isPhoneOrFax = /Phone|Fax Number/.test(newContactType.current);
              const invalidPhoneOrFax =
                newContactType.current &&
                isPhoneOrFax &&
                !isValidPhoneNumber(newContactValue.current || '');

              if (invalidPhoneOrFax || (!isPhoneOrFax && !newContactValue.current)) {
                setContactInputError(
                  isPhoneOrFax ? phoneErrorMessage() : 'Please enter a valid contact'
                );
              } else {
                handleNewContactCreated(
                  newContactType.current,
                  newContactValue.current,
                  setContactInputError,
                  callTodo,
                  onSave
                );
                setCreateNewContact(false);
                setContactInputError(null);
              }
            }}
            existingContacts={existingContacts}
            error={contactInputError}
            cypressTags={{
              existingContact: CONTACT_INPUT.EXISTING_CONTACT,
              addContact: CONTACT_INPUT.ADD_CONTACT,
              contactType: CONTACT_INPUT.CONTACT_TYPE,
              phoneNumberInput: CONTACT_INPUT.PHONE_NUMBER_INPUT,
            }}
          />
        </ContactInputContainer>
      )}
    </TableCell>
  );
};

//*** HELPER FUNCTIONS ***//

export const handleExistingContactSelected = (
  { contactType, value },
  communicationRecord = {},
  recipient,
  onSave
) => {
  const selectedContact = { contactType, contact: value.replace(/[- )(]/g, '') };

  'contact' in communicationRecord || (communicationRecord.contact = {});
  const existing = /Phone|Fax Number/.test(selectedContact.contactType)
    ? existingContactPhone(recipient, selectedContact)
    : existingContactNotPhone(recipient, selectedContact);

  let contact;
  let contactInfo = value.replace(/[- )(]/g, '');
  if (existing) {
    contact = existing;
    onSave && onSave({ contact, contactInfo });
  } else {
    if (!recipient) {
      // a private contact
      contact = selectedContact;
      onSave && onSave({ contact, contactInfo });
    }
  }
};

export const handleNewContactCreated = (
  contactType,
  contactValue,
  setContactInputError,
  communicationRecord,
  onSave
) => {
  const isOfficeExtensionOnly =
    contactValue &&
    contactValue.includes(';') &&
    contactValue.split(';')[0].length === 0 &&
    contactValue.split(';')[1].length > 0;

  if (isOfficeExtensionOnly) {
    setContactInputError('Please enter a valid contact');
  } else {
    communicationRecord.contact = { contact: contactValue, contactType };
    communicationRecord.contactInfo = contactValue;
    communicationRecord.occurrence_date = toUnixTime(new Date());
    onSave && onSave(communicationRecord);
  }
};

//*** STYLING ***//

const TableCell = styled.td({
  minWidth: 150,
  cursor: 'pointer',
  textOverflow: 'ellipsis',
  fontWeight: 300,

  'div, span, a': {
    maxWidth: 'unset',
  },
});

const ContactDisplayContainer = styled.div(({ editContact }) => {
  return editContact
    ? {
        paddingLeft: 4,
        width: '95%',
        borderCollapse: 'unset',
        border: `0.5px solid ${colors.linkBlue}`,
        boxShadow: `0px 0px 0.5px 1px ${colors.linkBlue}`,
        WebkitBoxShadow: `0px 0px 0.5px 1px ${colors.linkBlue}`,
        MozBoxShadow: `0px 0px 0.5px 1px ${colors.linkBlue}`,
        height: 32,
        marginTop: -1,
        display: 'flex',
        alignItems: 'center',
      }
    : { paddingLeft: 5, height: 32, marginTop: -1, display: 'flex', alignItems: 'center' };
});

const ContactInputContainer = styled.div({
  marginTop: -16,
  zIndex: 3,
  position: 'absolute',
  background: 'white',
});
