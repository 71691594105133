import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { helpers, styled as reactStyled } from 'react-free-style';
import OutsideClickHandler from 'react-outside-click-handler';
import styled from 'styled-components/macro';
import { elements, icons } from '@united-talent-agency/julius-frontend-components';
import {
  DatePicker,
  Icons,
  VerificationIcon,
  colors,
  StatusChangeHorizontal,
  Checkbox,
  Tooltip,
} from '@united-talent-agency/components';
import { InlineEditableContact } from './InlineEditableContact';
import { Notes } from './notes';
import { getCompanyId, getCompanyName } from '../../data/call-todo';
import { profileUrl, companyProfileUrl } from '../../support/urls';
import CallHistoryTable from './CallHistoryTable';
import { isValidDateTime, toDateTimeString } from '../../support/date';
import { useToasts } from 'react-toast-notifications';
import cypressTags, { CALL_ROW } from '../../support/cypressTags';
import EllipsisText from './EllipsisText';
import { useWindowSize, WIDTH_LIMIT } from '../../support/windowSize';

const { StarIcon, DeleteIcon, PencilIcon, HistoryIcon } = Icons;

const FF_NOTES_MODAL_ENABLED = process.env.REACT_APP_NOTES_MODAL_ENABLED === 'true';

const CallRow = ({
  item,
  hideBulkEdits = false,
  recipient,
  selected,
  statuses,
  onSelectedChange,
  onSave,
  onDeleteItem,
  onEditItem,
  styles,
  deskContacts,
}) => {
  const [history, setHistory] = useState(false);
  const [call, setCall] = useState(item);
  const [updatedAt, setUpdatedAt] = useState(item.updatedAt);
  const { addToast } = useToasts();
  const { width } = useWindowSize();

  useEffect(() => {
    const newUpdate = new Date(item.updatedAt).getTime();
    if (!updatedAt || updatedAt < newUpdate) {
      setCall(item);
      setUpdatedAt(newUpdate);
    }

    setCall(item);
  }, [item, updatedAt]);

  const updateCall = (values) => {
    return onSave({ ...call, ...values })
      .then((res) => setCall(res))
      .catch((e) => {
        setCall(call);
        addToast(e.message, { appearance: 'error', autoDismiss: true });
        return { error: 'failed update' };
      });
  };

  return (
    <CallTableRow
      data-cy={CALL_ROW.CALL_ROW}
      key={call ? call._id : ''}
      id={`call-row-${call._id}`}
      onDoubleClick={() => (FF_NOTES_MODAL_ENABLED ? null : onEditItem(call))}
      selected={selected}
    >
      {!hideBulkEdits && <RowCheckbox onChange={onSelectedChange} selected={selected} />}
      <Status callTodo={call} statuses={statuses} onSave={updateCall} styles={styles} />
      <Starred favorite={call.favorite} onSave={updateCall} />
      <CallRowName styles={styles} item={call} />

      {width >= WIDTH_LIMIT && <CompanyName item={call} styles={styles} />}
      <InlineEditableContact
        id="inline-editable-contact"
        deskAddressBookEntryContacts={deskContacts}
        callTodo={call}
        recipient={recipient}
        onSave={updateCall}
        canEdit={call.recipientId?.canEdit}
      />
      <CallDate occurrenceDate={call.occurrence_date} onSave={updateCall} styles={styles} />
      {FF_NOTES_MODAL_ENABLED ? (
        <Notes notes={call?.notes} onSave={updateCall} />
      ) : (
        <StaticNotes notes={call.description} id={call._id} styles={styles} />
      )}
      <Actions
        id={call._id}
        showHistory={() => setHistory(true)}
        onEdit={() => onEditItem(call)}
        onDelete={() => onDeleteItem(call)}
      />
      {history && (
        <td>
          <OutsideClickHandler onOutsideClick={() => setHistory(false)}>
            <CallHistoryTable callId={call._id} />
          </OutsideClickHandler>
        </td>
      )}
    </CallTableRow>
  );
};

// SUB-COMPONENTS
/**
 * Checkbox that allows for selecting the row for global actions
 * (ie bulk delete, bulk edit)
 *
 * @param {bool} selected: denotes checked status.
 * @param {function} onChange: function hook into the call-sheet that tracks
 *    what callTodos are selected.
 * @returns {JSX.Element}
 * @constructor
 */
const RowCheckbox = ({ selected, onChange }) => (
  <td>
    <Checkbox
      checked={selected}
      clearedBackground
      onChange={(e) => {
        const { checked } = e.currentTarget;
        onChange && onChange(checked);
      }}
    />
  </td>
);

/**
 * Allows the change of status for a given callTodo.
 *
 * @param {Object} callTodo: full callTodo object record.
 * @param {[Object]} statuses: array of possible statuses for the current desk.
 * @param {function} onSave: save hook that updates the callTodo.
 * @param {Object} styles: provided styling.
 * @returns {JSX.Element}
 * @constructor
 */
export const Status = ({ callTodo, statuses, onSave, styles }) => (
  <td className={styles.status}>
    <StatusChangeHorizontal
      code={callTodo ? callTodo.status : ''}
      item={callTodo}
      statuses={statuses}
      cypressTag={CALL_ROW.STATUS}
      onChange={(statusText) => {
        onSave({ status: statusText });
      }}
      small
    />
  </td>
);

/**
 * Inline star toggle
 *
 * @param {number || null} favorite: denotes whether or not the callTodo has be
 *  marked as a 'favorite'
 * @param {function} onSave: on save hook
 * @returns {JSX.Element}
 * @constructor
 */
const Starred = ({ favorite, onSave }) => {
  const [starred, setStarred] = useState(favorite === 1);
  return (
    <StarIconTableElement>
      <StarIconContainer
        data-cy={starred ? CALL_ROW.STAR : CALL_ROW.NO_STAR}
        onClick={() => {
          const isStarred = !starred;
          setStarred(isStarred);
          onSave({ favorite: isStarred ? 1 : 0 }).then((r) => r?.error && setStarred(starred));
        }}
      >
        <StarIcon
          id="star-icon"
          inline
          width={14}
          height={14}
          stroke={starred ? colors.nobel : colors.darkGrey}
          color={starred ? colors.sunshine : colors.white}
        />
      </StarIconContainer>
    </StarIconTableElement>
  );
};

/**
 * Contact Name for the call row
 *
 * @param {Object} styles
 * @param {Object} item: full callTodo record
 * @returns {JSX.Element}
 * @constructor
 */
export const CallRowName = ({ styles, item }) => {
  const showVerifiedIcon =
    item.recipientId && item.recipientId.verifiedByName && item.recipientId.verifiedOn;

  // all call row items have a recipientId (and should have a type in that object)
  // and only will be null if it is a Personal Contact
  // const entityType = item && item.recipientId ? item.recipientId.type : 'Personal Contact';
  const personId = ((item || {}).recipientId || {})._id;
  const name = item ? item.recipientName : 'Name not found';

  return (
    <td className={styles.name} style={{ paddingRight: '10px' }}>
      <div className={styles.person}>
        {/*fast fix hide this icon - coming back to it apparently*/}
        {/*<EntityTypeIcon id="entity-type-icon" entityType={entityType} />*/}
        <div className={styles.personName}>
          {personId ? (
            <a
              href={`${profileUrl}/${personId}`}
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <EllipsisText text={name} />
            </a>
          ) : (
            <EllipsisText text={name} />
          )}
        </div>
        {showVerifiedIcon && (
          <div style={{ marginLeft: '5px' }}>
            <VerificationIcon
              verifiedBy={item.recipientId.verifiedByName}
              verifiedOn={item.recipientId.verifiedOn}
              identifier={item.recipientId._id}
              includeExplanation
            />
          </div>
        )}
      </div>
    </td>
  );
};

/**
 * Displays the callTodo company name if it is present.
 * @param {Object} item: callTodo item.
 * @param {Object} styles: provided styling.
 * @returns {JSX.Element}
 * @constructor
 */
const CompanyName = ({ item, styles }) => {
  const companyId = getCompanyId(item);
  const companyName = getCompanyName(item);

  return (
    <td className={styles.company}>
      <div data-cy={CALL_ROW.COMPANY} className={styles.person}>
        {companyId ? (
          <a
            href={`${companyProfileUrl}/${companyId}`}
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <EllipsisText text={companyName} />
          </a>
        ) : (
          <EllipsisText text={companyName} />
        )}
      </div>
    </td>
  );
};

/**
 * Inline date picker
 * @param {number} occurrenceDate: A number representing the milliseconds elapsed between
 *    1 January 1970 00:00:00 UTC and the given value.
 * @param {function} onSave: hook for saving an updated Date
 * @param {Object} styles: provided styling classes
 * @returns {JSX.Element}
 * @constructor
 */
const CallDate = ({ occurrenceDate, onSave, styles }) => {
  const initialDateTime = new Date(occurrenceDate);
  const [dateTime, setDateTime] = useState(initialDateTime);

  const _date = (d) => `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
  const _time = (d) => {
    let hours = d.getHours();
    hours = hours % 12;
    hours = hours ? hours : 12;
    return `${hours}:${d.getMinutes()}`;
  };
  const _amPm = (d) => (d.getHours() > 12 ? 'pm' : 'am');

  const [date, setDate] = useState(_date(initialDateTime));
  const [time, setTime] = useState(_time(initialDateTime));
  const [twelveHour, setTwelveHour] = useState(_amPm(initialDateTime));

  const [editing, setEditing] = useState(false);
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    const newDateTime = new Date(`${date}, ${time} ${twelveHour}`);
    if (!editing && newDateTime.getTime() !== occurrenceDate) {
      if (updated) {
        setUpdated(false);
        setDateTime(newDateTime);
        onSave({ occurrence_date: newDateTime.getTime() });
      } else {
        const newDateTime = new Date(occurrenceDate);
        setDateTime(newDateTime);
        setDate(_date(newDateTime));
        setTime(_time(newDateTime));
        setTwelveHour(_amPm(newDateTime));
      }
    }
  }, [updated, date, time, twelveHour, occurrenceDate, onSave, editing]);

  const onBlur = () => {
    setUpdated(true);
    setEditing(false);
  };

  return (
    <>
      {editing ? (
        <td>
          <DatePicker
            timeIncluded
            onChangeTime={setTime}
            onChangeTwelveHourPeriod={setTwelveHour}
            timeString={time}
            twelveHourPeriod={twelveHour}
            dateString={date}
            onChange={setDate}
            onBlur={onBlur}
            inputFieldReadOnly
            focusPicker
            dateButtonCypressTag={CALL_ROW.DATE_DAY_BUTTON}
            dateLabelCypressTag={CALL_ROW.DATE_INNER_LABEL}
          />
        </td>
      ) : (
        <td
          className={styles.date}
          onClick={() => {
            setEditing(true);
          }}
        >
          <div data-cy={CALL_ROW.DATE}>
            <time dateTime={isValidDateTime(dateTime) ? dateTime.toISOString() : ''}>
              <EllipsisText text={toDateTimeString(dateTime)} />
            </time>
          </div>
        </td>
      )}
    </>
  );
};

/**
 * Display of notes within the call sheet when they're not being edited
 * @param {string} notes: callTodo description
 * @param {string} id: callTodo document id
 * @param {Object} styles: provided styling classes
 * @returns {JSX.Element}
 * @constructor
 */
const StaticNotes = ({ notes, id, styles }) => (
  <td>
    <div className={styles.note}>
      <span className={styles.noteBorder} />
      <span id={`item_description_${id}`} className={styles.noteContent}>
        {notes}
      </span>
    </div>
  </td>
);

/**
 * Contains the callRow icon-actions for showing history, deleting the callTodo and opening the
 * edit mode
 *
 * @param {string} id: callTodo record id
 * @param {function} onDelete: hook for deleting the callTodo
 * @param {function} onEdit: hook for opening the edit mode for the callTodo
 * @param {function} showHistory: hook for opening the history modal
 * @returns {JSX.Element}
 * @constructor
 */
const Actions = ({ id, onDelete, onEdit, showHistory }) => {
  const iconSize = 14;
  return (
    <ActionsTableCell>
      <ActionButtonWrapper>
        {/** TEMPORARY STINT - Remove edit button when field-level (onBlur) save enabled **/}
        {FF_NOTES_MODAL_ENABLED ? null : (
          <ActionButton id={`edit-button-${id}`} onClick={onEdit}>
            <PencilIcon width={iconSize} height={iconSize} />
          </ActionButton>
        )}
        <Tooltip text="Call History" place="bottom">
          <ActionButton
            id={`history-button-${id}`}
            onClick={() => {
              showHistory(true);
            }}
          >
            <HistoryIcon width={iconSize} height={iconSize} />
          </ActionButton>
        </Tooltip>
        <ActionButton
          id={`delete-button-${id}`}
          onClick={onDelete}
          data-cy={cypressTags.CALL_ROW.DELETE_CALL}
        >
          <DeleteIcon inline width={iconSize} height={iconSize} />
        </ActionButton>
      </ActionButtonWrapper>
    </ActionsTableCell>
  );
};

// STYLING
const StarIconTableElement = styled.td({
  width: 25,
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  height: 32,
});

const StarIconContainer = styled.div({
  width: 'max-content',
  cursor: 'pointer',
  display: 'flex',
});

const CallTableRow = styled.tr({
  '&:hover': {
    border: `1px solid ${colors.linkBlue}`,
    boxShadow: `0px 0px 1px 1px ${colors.linkBlue}`,
    WebkitBoxShadow: `0px 0px 0.5px 1px ${colors.linkBlue}`,
    MozBoxShadow: `0px 0px 0.5px 1px ${colors.linkBlue}`,
  },
  '> td': {
    fontSize: 12,
    fontWeight: 300,
    color: colors.text,
    paddingLeft: 2.5,
    paddingRight: 2.5,
  },
  '&:last-child': {
    borderBottom: `1px solid ${colors.border}`,
  },
  borderBottom: `1px solid ${colors.whiteSmoke}`,
  backgroundColor: (props) => (props.selected ? 'rgba(36, 149, 212, 0.1)' : colors.white),
  height: 32,
  position: 'relative',

  // 'div, span, a': {
  //   maxWidth: '100%',
  // },
});

const ActionsTableCell = styled.td({
  whiteSpace: 'nowrap',
  textAlign: 'left',
  width: 60,
});

const ActionButtonWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
});

const ActionButton = styled.div({
  cursor: 'pointer',
  display: 'flex',
  marginLeft: 10,
});

const withStyles = reactStyled({
  name: {
    minWidth: '116px',
  },
  person: {
    display: 'flex',
    cursor: 'default',
    alignItems: 'center',
    maxWidth: '100%',
  },
  personName: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
  company: {},
  contact: {
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    fontWeight: 300,
  },
  date: {
    whiteSpace: 'nowrap',
    '&:hover': {
      cursor: 'pointer',
    },
    maxWidth: '100%',
  },
  note: {
    display: 'flex',
    alignItems: 'center',
  },
  noteContent: {
    width: '350px',
    float: 'left',
    overflowWrap: 'break-word',
    paddingLeft: '5px',
  },
  noteBorder: {
    display: 'inline-block',
    width: 1,
    height: 20,
    backgroundColor: colors.whiteSmoke,
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
  },
  button: helpers.merge(elements.reset, elements.actionable, {
    padding: 10,
    lineHeight: 0,
  }),
  editIcon: icons.pencil,
  declineIcon: icons.cross,
  deleteIcon: icons.trashcan,
  status: {
    paddingLeft: 7,
    paddingRight: 7,
    width: 55,
  },
  faOutline: {
    textShadow: '0px 0px 1px black',
  },
  iconSize: {
    fontSize: 14,
  },
  link: {
    color: '#2187B9',
    '&:hover': {
      color: '#2187B9',
    },
    maxWidth: '100%',
  },
  maxWidth: {
    maxWidth: '100%',
  },
});

const withState = connect((store) => {
  const { user } = store;
  return { user };
});

export default withState(withStyles(CallRow));
