import _ from 'lodash';
import { createCommunication, patchCommunication } from '../api/communications';
const SHOW_COMPANY = process.env.REACT_APP_ONX_13_FEATURE === 'true';

export const getCompanyName = (callItem) => {
  const groups = _.get(callItem, 'recipientId.groupMembership', []);

  let primaryGroup;
  if (SHOW_COMPANY) {
    primaryGroup = _.get(callItem, 'recipientId.groupMembership', {});
  } else {
    try {
      primaryGroup = groups.find((group) => group.primary);
    } catch (e) {
      console.log('ONX-13 feature flags are inconsistent between projects and the API server!');
    }
  }

  return primaryGroup && primaryGroup.group ? primaryGroup.group.name : '';
};

export const getCompanyId = (callItem) => {
  const groups = _.get(callItem, 'recipientId.groupMembership', []);
  let primaryGroup;
  if (SHOW_COMPANY) {
    primaryGroup = _.get(callItem, 'recipientId.groupMembership', {});
  } else {
    try {
      primaryGroup = groups.find((group) => group.primary);
    } catch (e) {
      console.log('ONX-13 feature flags are inconsistent between projects and the API server!');
    }
  }

  return primaryGroup && primaryGroup.group ? primaryGroup.group._id : '';
};

export const saveCall = (payload) => {
  //intercept for outlook contact, they should have recipientId as null to ignore any kind of contact creation
  if (payload.recipientId && payload.recipientId.outlook) {
    payload.recipientName = payload.recipientId.name;
    payload.recipientId = null;
  } else if (!payload.recipientId && payload.recipientName) {
    // this is a private contact, that should have the recipientId as its name
    payload.recipientId = payload.recipientName;
  }

  // TODO - address mismatched data expectation: https://app.asana.com/0/1199728702258333/1201186244661870/f
  //  "satisfies the v1 update mechanism, can be removed with the v3 impl" wasn't able to remove with v3
  if (payload.deskId && payload.deskId._id) {
    payload.deskId = payload.deskId._id;
  }

  return payload._id ? patchCommunication(payload) : createCommunication(payload);
};
