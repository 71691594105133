import * as React from 'react';
import Media from 'react-media';
import * as sizes from '../../../styles/sizes';

import Wrapper from './wrapper';

const MediaWrapper = ({ children, ...componentProps }) => (
  <Media query={sizes.smallBreakpoint}>
    {(matches) =>
      matches ? (
        <Wrapper smallView {...componentProps}>
          {children}
        </Wrapper>
      ) : (
        <Wrapper {...componentProps}>{children}</Wrapper>
      )
    }
  </Media>
);

export default MediaWrapper;
