import { get } from './index.js';
const ROUTE = '/change';

export const getHistory = (callId) => {
  const queryParams = [{ fieldId: callId }, { $populate: 'userId' }, { '$sort[timestamp]': -1 }];
  return get(ROUTE, queryParams).then((response) => {
    const { errors, data } = (response || {}).body || {};
    if (errors) {
      // eslint-disable-next-line no-console
      console.error(`Handled API Error from ${ROUTE}`, errors);
      return Promise.reject(errors[0]);
    }
    return data;
  });
};
