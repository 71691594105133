/* eslint-disable react/display-name */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { helpers, styled } from 'react-free-style';
import classnames from 'classnames';
import { debounce } from 'lodash';
import {
  StatusInput,
  PhoneInput,
  ContactTypeSelector,
  DatePicker,
  Input,
  fromStringToE164,
} from '@united-talent-agency/components';
import * as colors from '../../../styles/colors';
import * as elements from '../../../styles/elements';
import * as sizes from '../../../styles/sizes';
import { CALL_FORM, CONTACT_INPUT } from '../../../support/cypressTags';
import withToastHOC from '../../../components/withToastHOC';

import {
  TextArea,
  PersonInput,
  DoneButtons,
} from '@united-talent-agency/julius-frontend-components';

import {
  selectContact,
  searchDeskContacts,
  getPersonPrivateContacts,
} from '@united-talent-agency/julius-frontend-store';
import { getPeopleByTypeBuckets } from '../../../api/people';
import { getOutlookContacts } from '../../../api/outlook';

import {
  existingContactPhone,
  existingContactNotPhone,
  updateDeskAddress,
} from '../../../support/contact';

export class CallForm extends Component {
  constructor(props) {
    super(props);
    this.searchPerson = debounce(this.searchPerson, 400);

    this.contactPriorities = [
      'Office Phone',
      'Assistant Phone',
      'Mobile Phone',
      'Home Phone',
      'Email',
      'Assistant Email',
      'Personal Email',
      'Message',
      'Fax Number',
      'Unknown',
    ];
    this.state = {
      focus: 'name',
      noteChange: false,
      isSaving: false,
      name: (this.props.callForm || {}).name || null,
      dateString: '',
      timeString: '',
      twelveHourPeriod: '',
      personSearchResults: [],
      outlookSearchResults: [],
      showTimeSelect: false,
      deskContactResults: [],
      recipientIdPrivateContacts: [],
      deskAddressBookEntryContacts: [],
    };
  }

  componentDidMount() {
    const { callTodo = {}, deskId, dispatch } = this.props;
    const { _id, recipientId, recipientName } = callTodo;
    const recipient = { name: recipientName, id: recipientId };

    const recipientContacts = updateDeskAddress(_id, deskId, recipient, dispatch);
    if (recipientContacts) {
      this.setState({ deskAddressBookEntryContacts: recipientContacts });
    }

    const currentTimeDisplay = new Date(callTodo.occurrence_date).toLocaleString(
      navigator.language,
      {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      }
    );

    const currentDateString = currentTimeDisplay.split(' ')[0].slice(0, -1);
    const currentTimeString = currentTimeDisplay.split(' ')[1];
    const currentTwelveHourPeriod = currentTimeDisplay.split(' ')[2];

    this.setState({
      dateString: currentDateString,
      timeString: currentTimeString,
      initialTimeString: currentTimeString,
      twelveHourPeriod: currentTwelveHourPeriod,
    });
  }

  onSelectPerson(person) {
    const { callTodo, onChange, callForm = {} } = this.props;

    //only "master-data" people have private contacts
    person &&
      person.type &&
      person.type !== 'Outlook' &&
      person._id &&
      this.props.dispatch(getPersonPrivateContacts(person._id)).then((response) => {
        const privateContacts = response?.body?.privateContacts || [];
        this.setState({ recipientIdPrivateContacts: privateContacts });
      });
    // } else {
    //   this.setState({ recipientIdPrivateContacts: [] });
    // }

    //callForm.person.results = [];
    callTodo.recipientId = person;
    callTodo.recipientName = person.name;
    callTodo.contact = this.getPrimaryContact(person.contacts);
    callTodo.contactInfo = callTodo.contact && callTodo.contact.contact;
    callTodo.companyName = person.companyId && person.companyId.name;
    if (callTodo.contact) {
      this.setFocus('notes');
    } else {
      this.setFocus('info');
    }

    //reset desk address book search results after person select
    this.setState({ deskContactResults: [], personSearchResults: [], outlookSearchResults: [] });

    callForm.outlook && (callForm.outlook.results = []);

    onChange && onChange(callTodo);
  }

  /************************************************************
   *         Get Primary Contact from contacts array          *
   * -------------------------------------------------------- *
   *  1. If there are multiple Primary set, the order is:     *
   *     Phone > Email > Other                                *
   *  2. If no priority contact exists, then order will be:   *
   *     Work > Assistant Phone > Mobile > Home > Email >     *
   *     Assistant email > Personal Email > Message > Fax     *
   ************************************************************/
  getPrimaryContact(contacts) {
    let primaryContact = null;
    if (contacts) {
      const primaryContacts = contacts.filter((c) => c.primary);

      if (primaryContacts.length > 0) {
        // has primary contacts
        this.contactPriorities.forEach((contactType) => {
          const contact = primaryContacts.find((c) => c.contactType === contactType);
          if (contact && !primaryContact) {
            primaryContact = contact;
            return;
          }
        });
      } else {
        // no primary contacts
        this.contactPriorities.forEach((contactType) => {
          const contact = contacts.find((c) => c.contactType === contactType);
          if (contact && !primaryContact) {
            primaryContact = contact;
            return;
          }
        });
      }
    }

    return primaryContact || {};
  }

  searchPerson(name, desk) {
    const { dispatch } = this.props;
    const searchPeople = getPeopleByTypeBuckets(name).then((result) => {
      this.setState({ personSearchResults: result.data });
    });
    const searchDesk = this.props.dispatch(searchDeskContacts(desk, name)).then((result) => {
      const deskPeople = result.body || [];
      this.setState({ deskContactResults: deskPeople });
    });
    const searchOutlook = getOutlookContacts(name, desk, dispatch).then((result) => {
      this.setState({ outlookSearchResults: result });
    });
    return Promise.all([searchPeople, searchDesk, searchOutlook]);
  }

  onSelectContact(contact) {
    this.props.dispatch(selectContact(contact));
    this.setFocus('notes');
  }

  setFocus(input) {
    this.setState({ focus: input });
    setTimeout(() => {
      const inputName = input + 'Input';
      if (inputName in this && this[inputName]) {
        if ('textarea' in this[inputName]) {
          this[inputName]['textarea'].focus();
        }
      }
    }, 100);
  }

  filterPrivate(results) {
    const { deskId } = this.props;
    return (
      results &&
      results.filter((item) => {
        if (item.private) {
          return item.deskId + '' === deskId + '';
        }
        return true;
      })
    );
  }

  async submit(e) {
    e.preventDefault();

    const isValid = this.validateCallForm();
    if (!isValid) {
      return;
    }

    const { callTodo, onSave } = this.props;

    // Add description as first element of notes
    callTodo.notes = [{ note: callTodo.description }];

    this.setState({ isSaving: true });
    // onSave creates endpoint call Promise via call-todo.saveCall
    onSave &&
      onSave(callTodo)
        .catch(() => {
          this.setState({ isSaving: false });
        })
        .then(() => {
          this.setState({ isSaving: false });
        });
  }

  isExistingOutlookContact() {
    const { callTodo = {} } = this.props;
    return (
      callTodo.recipientId?.outlook &&
      callTodo.contact?.contact &&
      (existingContactPhone(callTodo.recipientId, callTodo.contact) ||
        existingContactNotPhone(callTodo.recipientId, callTodo.contact))
    );
  }

  validateCallForm() {
    const { callTodo } = this.props;
    const missingFields = [];

    if (
      !(
        callTodo.recipientId ||
        (callTodo.recipientName && callTodo.recipientName.trim().length > 0)
      )
    ) {
      missingFields.push('Name');
    }
    if (!callTodo.status) {
      missingFields.push('Status');
    }
    if (!callTodo.contact.contact) {
      missingFields.push('Contact Info');
    }
    if (!callTodo.contact.contactType) {
      missingFields.push('Type');
    }

    if (missingFields.length) {
      const lf = new Intl.ListFormat('en');
      const errorMessage = `${lf.format(missingFields)} ${
        missingFields.length > 1 ? 'are' : 'is'
      } required to create a Call Record.`;
      this.props.addToast(errorMessage, { appearance: 'error', autoDismiss: true });
      return false;
    }

    return true;
  }

  render() {
    const { styles = {}, isNotesFocus, callTodo = {}, statuses, onChange, onCancel } = this.props;
    const {
      recipientIdPrivateContacts,
      personSearchResults = [],
      outlookSearchResults = {},
      deskAddressBookEntryContacts,
    } = this.state;
    const searchResults = personSearchResults;
    const outlook = { results: outlookSearchResults };
    const userRegion = navigator.language.split('-')[1];
    const { recipientId, contact = {}, contactInfo } = callTodo;

    const isPhoneOrFax = contact && /Phone|Fax Number/.test(contact.contactType);
    const isOfficeExtensionOnly =
      contactInfo &&
      contactInfo.includes(';') &&
      contactInfo.split(';')[0].length === 0 &&
      contactInfo.split(';')[1].length > 0;

    const currentStatus =
      statuses && (statuses.find(({ status }) => status === callTodo.status) || statuses[0]);
    callTodo.status = currentStatus && currentStatus.status;

    const statusChildren = statuses && (
      <StatusInput
        data-cy={CALL_FORM.STATUS_TEXT_BOX}
        options={statuses.filter((code) => code.name && code.status)}
        value={currentStatus}
        onChange={(value) => {
          callTodo.status = value.status;
          onChange && onChange(callTodo);
        }}
      />
    );

    //combine "master data" public contacts with "master data" private contacts (that their desk has access to)
    const masterDataContacts = recipientId
      ? (recipientId.contacts || []).concat(recipientIdPrivateContacts || [])
      : [];

    //combine masterDataContacts with desk address book entries (just fall-through logic, should never be both?)
    const existingContacts = masterDataContacts
      .concat(deskAddressBookEntryContacts)
      .map(({ _id, contactType = 'Unknown', contact = '', primary }) => ({
        _id,
        contactType,
        value: contact,
        primary,
        private: contactType && !contact,
      }));

    const contactAddable =
      !this.isExistingOutlookContact() &&
      callTodo.recipientId?.type !== 'Employee' &&
      (!callTodo.recipientId ||
        (callTodo.recipientId?._id?.length > 0 && callTodo.recipientId?.canEdit) ||
        callTodo.recipientId?.type === 'Desk Contact');
    let tooltipMessage = undefined;
    if (callTodo.recipientId?.type === 'Employee') {
      tooltipMessage =
        'Contact information for an employee can only be edited (by them) in Workday.';
    } else if (callTodo.recipientId?.type === 'Client') {
      tooltipMessage =
        'Please contact a client team member to make changes to this client profile.';
    } else {
      tooltipMessage = 'Phonesheet cannot edit Outlook (O) or Personal (P) contacts.';
    }

    return (
      <form
        className={styles.container}
        onSubmit={(e) => this.submit(e)}
        data-cy={CALL_FORM.CALL_FORM}
      >
        <div className={styles.mainContainer}>
          <div className={styles.subContainer}>
            <div className={styles.section}>{statusChildren}</div>

            <div
              data-cy={CALL_FORM.NAME_TEXT_BOX}
              className={classnames(styles.section, styles.nameInput)}
            >
              <PersonInput
                inputRef={(el) => {
                  this.nameInput = el;
                }}
                results={searchResults.concat(this.state.deskContactResults)}
                outlook={outlook}
                showOutlook={true}
                value={callTodo.recipientName}
                onChange={(value) => {
                  callTodo.recipientId = null;
                  callTodo.recipientName = value;
                  if (value) {
                    this.searchPerson(value, this.props.deskId);
                  }
                  onChange && onChange(callTodo);
                }}
                onSelect={(person) => {
                  this.onSelectPerson(person);
                }}
                focus={!isNotesFocus}
              />
            </div>
            <div className={classnames(styles.section, styles.contactInput)}>
              <div id="contact-type-selector" style={{ marginTop: 6 }}>
                <ContactTypeSelector
                  value={{ contactType: contact.contactType || 'Unknown', value: contact.contact }}
                  existing={existingContacts}
                  onChange={({ contactType = 'Unknown', value }) => {
                    callTodo.contact = { contactType, contact: value };
                    callTodo.contactInfo = value;
                    onChange && onChange(callTodo);
                  }}
                  extSeparator=" x"
                  addable={contactAddable}
                  tooltipMessage={tooltipMessage}
                  cypressTags={{
                    existingContact: CONTACT_INPUT.EXISTING_CONTACT,
                    addContact: CONTACT_INPUT.ADD_CONTACT,
                    contactType: CONTACT_INPUT.CONTACT_TYPE,
                  }}
                />
              </div>
              {contact ? (
                isPhoneOrFax ? (
                  <div
                    data-cy={CALL_FORM.PHONE_TEXT_BOX}
                    id="phone-or-fax-input"
                    style={{ marginTop: 2, width: '100%' }}
                  >
                    <PhoneInput
                      userRegion={userRegion}
                      value={contactInfo}
                      title={contact.contactType}
                      onChange={(value) => {
                        callTodo.contactInfo = value;
                        callTodo.contact = Object.assign({}, callTodo.contact);
                        callTodo.contact.contact = value;
                        delete callTodo.contact._id;
                        const existing = existingContactPhone(
                          callTodo.recipientId,
                          callTodo.contact
                        );
                        if (existing) {
                          callTodo.contact._id = existing._id;
                        }
                        onChange && onChange(callTodo);
                      }}
                      disabled={!contactAddable}
                      cypressTags={{
                        phoneNumberInput: CONTACT_INPUT.PHONE_NUMBER_INPUT,
                      }}
                    />
                    {contactInfo &&
                      !isOfficeExtensionOnly &&
                      !fromStringToE164(contactInfo).countryCode && (
                        <p style={{ color: 'red', fontSize: '12px', textAlign: 'center' }}>
                          Invalid Number
                        </p>
                      )}
                  </div>
                ) : (
                  <div id="non-phone-or-fax-input" style={{ marginTop: 6, minWidth: 250, flex: 1 }}>
                    <Input
                      value={contactInfo}
                      title={contact.contactType}
                      onChange={(value) => {
                        callTodo.contactInfo = value;
                        callTodo.contact.contact = value;
                        delete callTodo.contact._id;
                        const existing = existingContactNotPhone(
                          callTodo.recipientId,
                          callTodo.contact
                        );
                        if (existing && existing._id) {
                          callTodo.contact._id = existing._id;
                        }
                        onChange && onChange(callTodo);
                      }}
                      disabled={!contactAddable}
                    />
                  </div>
                )
              ) : null}
            </div>
          </div>
          <div className={styles.subContainer}>
            <div className={classnames(styles.section, styles.datetimePicker)}>
              {this.renderDatetimePicker()}
            </div>
            <div
              data-cy={CALL_FORM.NOTES_TEXT_BOX}
              className={classnames(styles.section, styles.notesInput)}
            >
              <TextArea
                inputRef={(el) => {
                  this.notesInput = el;
                }}
                id="notes-input"
                title="Notes"
                onChange={(notes) => {
                  callTodo.description = notes;
                  onChange && onChange(callTodo);
                }}
                value={callTodo.description}
                focus={isNotesFocus}
              />
            </div>
            <div className={styles.doneButtons}>
              <DoneButtons
                isActive={true}
                isSaving={this.state.isSaving}
                onDone={() => {
                  onCancel && onCancel();
                }}
                cypressSaveTag={CALL_FORM.CHECKMARK_BUTTON}
                cypressCloseTag={CALL_FORM.CANCEL_BUTTON}
              />
            </div>
          </div>
        </div>
      </form>
    );
  }

  handleDatetimeChange(dateString, timeString, twelveHourPeriod) {
    const { callTodo, onChange } = this.props;
    const od = new Date(`${dateString}, ${timeString} ${twelveHourPeriod}`);
    callTodo.occurrence_date = od.getTime();
    onChange && onChange(callTodo);
  }

  renderDatetimePicker() {
    const { dateString, timeString, twelveHourPeriod, initialTimeString } = this.state;
    return (
      <DatePicker
        timeIncluded
        onChangeTime={(time) => {
          this.setState({ timeString: time });
          const { dateString, twelveHourPeriod } = this.state;
          this.handleDatetimeChange(dateString, time, twelveHourPeriod);
        }}
        onChangeTwelveHourPeriod={(period) => {
          this.setState({ twelveHourPeriod: period });
          const { dateString, timeString } = this.state;
          this.handleDatetimeChange(dateString, timeString, period);
        }}
        clearTime={() => {
          this.setState({ timeString: initialTimeString });
          const { dateString, twelveHourPeriod } = this.state;
          this.handleDatetimeChange(dateString, initialTimeString, twelveHourPeriod);
        }}
        timeString={timeString}
        twelveHourPeriod={twelveHourPeriod}
        dateString={dateString}
        onChange={(date) => {
          this.setState({ dateString: date });
          const { timeString, twelveHourPeriod } = this.state;
          this.handleDatetimeChange(date, timeString, twelveHourPeriod);
        }}
        inputFieldReadOnly
      />
    );
  }
}

const withStyles = styled({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  mainContainer: {
    display: 'flex',
    background: colors.contentBackground,
    border: `1px solid ${colors.darkBorder}`,
    padding: 6,
    [`@media ${sizes.mediumBreakpoint}`]: {
      flexDirection: 'column',
    },
    [`@media ${sizes.mobileBreakpoint}`]: {
      paddingTop: 15,
    },
  },
  subContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    [`@media ${sizes.mobileBreakpoint}`]: {
      flexDirection: 'column',
    },
  },
  section: {
    margin: 6,
    [`@media ${sizes.mobileBreakpoint}`]: {
      margin: '0 15px 15px',
    },
  },
  button: helpers.merge(elements.reset, elements.actionable, {
    padding: 10,
    lineHeight: 0,
  }),
  nameInput: {
    flex: 1,
    minWidth: 70,
  },
  contactInput: {
    display: 'flex',
    marginTop: '0px!important',
  },
  notesInput: {
    flex: 1,
    minWidth: 70,
  },
  doneButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '0!important',
    marginBottom: '0!important',
  },
  alertContainer: {
    textAlign: 'center',
    width: '450px',
    fontSize: '12px',
    background: 'white',
    opacity: 1,
    padding: '40px',
    boxShadow: '0 20px 75px rgba(0, 0, 0, 0.23)',
    color: '#4a4a4a',
    border: '1px solid #4a4a4a',
  },
  alertButton: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#000',
    margin: '5px',
  }),
  private: {
    position: 'absolute',
  },
  privateLabel: {
    fontSize: 12,
    fontWeight: '100',
    display: 'block',
    position: 'relative',
    cursor: 'pointer',
    userSelect: 'none',
    ' input': {
      postiion: 'absolute',
      opacity: 0,
      cursor: 'pointer',
    },
    ' input:checked': {
      display: 'block',
    },
    ' .privateCheckbox:after': {
      left: '9px',
      top: '5px',
      width: '5px',
      height: '10px',
      border: 'solid white',
      borderWidth: '0 3px 3px 0',
      transform: 'rotate(45deg)',
    },
  },
  privateCheckbox: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 15,
    width: 15,
    backgroundColor: '#eee',
    '&:after': {
      content: '',
      position: 'absolute',
      display: 'none',
    },
  },
});

const withState = connect((state) => {
  const callForm = state.callForm;
  const deskId = state.desk.current._id;
  const desk = state.desk.current;
  const user = state.user;

  return { callForm, deskId, user, desk };
});

export default withState(withStyles(withToastHOC(CallForm)));
