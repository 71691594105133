import { get, patch, post, remove } from './index.js';
const ROUTE = '/phone-sheet/communications';
export const DEFAULT_CALL_FETCH_LIMIT = 20;

/**
 * @typedef {Object} FilterParams
 * @property {string} [desk]: current desk, document id
 * @property {number} [favorite]: 1 or undefined
 * @property {string} [filter]: comma separated string of statuses
 * @property {boolean} [hideFutureCalls]
 * @property {number} [lastUpdate]: epoch-number timestamp of the last GET call
 * @property {number} [page]: current page variable, parsed from URL
 * @property {string} [query]: current search query
 * */
/**
 * @typedef {Object} ResponseMeta
 * @property number limit: array of CallTodo documents
 * @property boolean more:
 * @property number skip:
 * @property number total:
 */

/**
 * Parses and packages query parameters
 * @param {FilterParams} _: deskId along with url-based query parameters
 * @returns {[Object]}: parsed & packaged query parameters.
 **/
export const getQueryParams = ({
  desk,
  filter = '',
  query,
  favorite,
  hideFutureCalls,
  lastUpdate,
}) => {
  /** @type {[Object]} */
  const queryParams = [{ deskId: desk || 'ForgotToSetDesk' }];

  if (query) {
    //wrap search text in double quotes to force an exact match search
    queryParams.push({ search: `"${query}"` });
  }
  filter
    .split(',')
    .filter((s) => s)
    .forEach((status) => {
      queryParams.push({ status });
    });
  if (favorite) {
    queryParams.push({ favorite });
  }
  if (lastUpdate) {
    queryParams.push({ 'updatedAt[gt]': lastUpdate });
  }
  if (hideFutureCalls) {
    const midnightTonight = new Date().setHours(24, 0, 0, 0);
    queryParams.push({ 'occurrence_date[lt]': midnightTonight });
  }
  return queryParams;
};

/**
 * Creates a CallTodo
 *
 * @param {string} deskId: current deskId
 * @param {string} status: CallTodo status
 * @param {string} [recipientId]: recipient document id within the DB
 * @param {string} recipientName: name of the person who called the desk
 * @param {Object} contact: contact information of the recipient
 * @param {number} occurrence_date: epoch-number denoting the user-specified, date of the CallTodo
 * @param {string} description: string of CallTodo "notes"
 * @param {function} _post: dependency injected HTTP post method.
 * @returns {Promise<never>|Promise<*>}: returns a new CallTodo item if the promise is fulfilled.
 */
export const createCommunication = (
  { deskId, status, recipientId, recipientName, contact, occurrence_date, description },
  _post = post
) => {
  if (!deskId) {
    return Promise.reject('deskId is required');
  }
  if (!status) {
    return Promise.reject('status is required');
  }
  if (!recipientName) {
    return Promise.reject('recipientName is required');
  }
  if (!recipientId && !recipientName) {
    return Promise.reject('recipientId or recipientName is required');
  }
  if (!contact) {
    return Promise.reject('contact is required');
  }
  if (!occurrence_date) {
    return Promise.reject('occurrence_date is required');
  }

  const payload = {
    deskId,
    status,
    description,
    recipientId,
    recipientName,
    contact,
    occurrence_date,
  };

  //new notes structure
  if (description?.trim()) {
    payload.notes = [{ note: description }];
  }

  return _post(ROUTE, payload).then((response) => {
    const { error, data } = (response || {}).body || {};
    if (error) {
      // eslint-disable-next-line no-console
      console.error(`Handled API Error from ${ROUTE}`, error);
      return Promise.reject(error[0]);
    }
    return data;
  });
};

/**
 * Retrieves an array of CallTodo records
 *
 * Makes an API call to the GET /phone-sheet/communications resource
 *
 * @param {FilterParams} filterParams: deskId in addition to the query parameters extracted from the current URL
 * @param {number} [skip]: amount of callTodos to skip over
 * @param {number} [limit]: maximum amount of callTodos to return
 * @param {[string]} [show]: additional callTodo model fields to display
 * @param {string} [sort]: how to sort the new callTodo records.
 * @param _get: injected HTTP GET method.
 * @returns { Promise<{data: [Object], meta: ResponseMeta}>}: returns an array of CallTodos
 **/
export const getCommunications = (
  filterParams,
  {
    skip = 0,
    limit = DEFAULT_CALL_FETCH_LIMIT,
    show = ['contact', 'notes', 'recipientId', 'deskId'],
    sort = 'occurrence_date DESC',
  } = {},
  _get = get
) => {
  //use search filter to build v3 search params
  /** @type {Array.<Object>} */
  const queryParams = getQueryParams(filterParams);

  //v3 specific params
  if (skip) {
    queryParams.push({ skip });
  }
  if (limit) {
    queryParams.push({ limit });
  }
  if (show) {
    show.forEach((field) => {
      queryParams.push({ show: field });
    });
  }
  if (sort) {
    queryParams.push({ sort });
  }

  return _get(ROUTE, queryParams).then((response) => {
    const { errors, data, meta } = (response || {}).body || {};
    if (errors) {
      // eslint-disable-next-line no-console
      console.error(`Handled API Error from ${ROUTE}`, errors);
      return Promise.reject(errors[0]);
    }
    return { data, meta };
  });
};

/**
 * BULK-Update for CallTodo Statuses
 *
 *
 * @param {FilterParams} filterParams: The search parameters maintained in redux for filtering down the call list
 * @param {boolean} [all]: if true, update all records identified by the query parameters.
 * @param {[string]} [except]: Array of communication._id's (call_todo._id's) that are exceptions to the [filterParams]
 *    and the [all] param.  Note: if [all] is false, except is the list of id's to update
 * @param {string} status: The status code to set the identified records to
 * @param {function} _patch: injected HTTP PATCH method.
 * @returns {Promise<never>|Promise<{data: [Object], meta: ResponseMeta}>}: if fulfilled, the response body contains
 *  data that consists of an array of the updated CallTodos and response meta information.
 **/
export const patchCommunications = ({
  filterParams,
  all = false,
  except = [],
  status,
  _patch = patch,
}) => {
  if (!status) {
    return Promise.reject('Must supply status for this patch');
  }
  //use search filter to build v3 search params
  const queryParams = getQueryParams(filterParams);
  const update = {
    status,
    options: { all, except },
  };
  return _patch(ROUTE, update, queryParams).then((response) => {
    const { errors, data, meta } = (response || {}).body || {};
    if (errors) {
      // eslint-disable-next-line no-console
      console.error(`Handled API Error from ${ROUTE}`, errors);
      return Promise.reject(errors[0]);
    }
    return { data, meta };
  });
};

/**
 * BULK-Delete for CallTodos
 *
 *
 * @param {FilterParams} filterParams: The search parameters maintained in redux for filtering down the call list
 * @param {boolean} [all]: if true, delete all records identified by the query parameters.
 * @param {[string]} [except]: Array of communication._id's (call_todo._id's) that are exceptions to the [filterParams]
 *    and the [all] param.  Note: if [all] is false, except is the list of id's to delete
 * @param {function} _remove: injected HTTP DELETE method.
 * @returns {Promise<never>|Promise<*>}: http delete request promise.
 **/
export const deleteCommunications = (filterParams, all = false, except = [], _remove = remove) => {
  //use search filter to build v3 search params
  const queryParams = getQueryParams(filterParams);
  const toDelete = { options: { all, except } };

  return _remove(`/phone-sheet/communications`, toDelete, queryParams).then((response) => {
    const { errors, data, meta } = (response || {}).body || {};
    if (errors) {
      //errors is an array, but v3 only returns a single error in the array
      //this is for api-handled errors,
      //  - unhandled errors shouldnt get this far (such as network outage)
      console.error('Handled API Error from /phone-sheet/communications', errors);
      return Promise.reject(errors[0]);
    }
    return { data, meta };
  });
};

/**
 * Update for a CallTodo
 *
 * @param communication
 * @param _patch
 * @returns {Promise.<{data: [CallTodo], meta: ResponseMeta}>}
 **/
export const patchCommunication = (communication, _patch = patch) => {
  delete communication.relevance;
  delete communication.contacts;

  // TODO - [ASANA-1201395332365716] Clean up data: favorite should be changed to a boolean type and all data should be migrated.
  communication.favorite = [1, 2, 3, 4].includes(communication.favorite) ? 1 : 0;

  return _patch(`${ROUTE}/${communication._id}`, communication).then((response) => {
    const { error, data, meta } = (response || {}).body || {};
    if (error) {
      // eslint-disable-next-line no-console
      console.error(`Handled API Error from ${ROUTE}`, error);
      return Promise.reject(error[0]);
    }
    return { data, meta };
  });
};

/**
 * Deletes a CallTodo
 * @param {string} communicationId: CallTodo document id in the DB
 * @param {function} _remove: injected HTTP DELETE method.
 * @returns {Promise<*>}: http delete request promise.
 **/
export const deleteCommunication = (communicationId, _remove = remove) => {
  return _remove(`${ROUTE}/${communicationId}`);
};
