import { gql } from '@apollo/client';

const buildCallRecordsQuery = (user) => {
  const CALL_RECORDS = gql`
    query callRecords($where: CallRecordWhere, $take: Int, $skip: Int, $order: CallRecordOrder) {
      callRecords(where: $where, take: $take, skip: $skip, order: $order) {
        callRecords {
          description
          desk {
            name
            owner
            extension
            callerPass
            callerExt
            agentId
            assistantId
            _id
          }
          contactInfo
          status
          companyName
          occurrence_date
          contact {
            _id
            contactType
            contact
          }
          _id
          createdBy {
            _id
          }
          updatedAt
          notes {
            note
            _id
            createdBy
            updatedBy
            createdAt
            updatedAt
          }
          favorite
          recipient(email: "${user.email}", azure_id: "${user.azure_id}") {
            _id
            name
            contacts {
              _id
              contact
              contactType
              description
              primary
              private
              source
            }
            groupMembership {
              _id
              group
              title
              primary
              endDate
              updatedAt
              createdAt
              startDate
            }
            companies {
              _id
              name
              type
            }
            canEdit
            type
            verified
            verifiedBy
            verifiedByName
            verifiedOn
            url
            title
            profile_pic
            phone
            email
          }
          deskId
          recipientId
          recipientName
          createdAt
          updatedBy {
            _id
            email
            first_name
            personId
            azure_id
            last_name
          }
          deletedBy {
            _id
            email
            first_name
            personId
            azure_id
            last_name
          }
        }
        meta {
          total
          skip
          limit
          more
        }
      }
    }
  `;

  return CALL_RECORDS;
};

const DESK_STATUS = gql`
  query deskStatus($where: DeskWhere) {
    desks(where: $where) {
      statuses {
        name
        _id
        color
        deskId
        hasCallRecord
        order
        status
        type
      }
    }
  }
`;

export { buildCallRecordsQuery, DESK_STATUS };
