import React from 'react';
import Modal from 'react-modal';
import { styled, helpers } from 'react-free-style';
import classnames from 'classnames';
import * as elements from '../styles/elements';

const DuplicateCallModal = (props) => {
  const { isOpen, onCancel, onCreateNew, onUseExisting, styles } = props;
  return (
    <Modal isOpen={isOpen} onRequestClose={onCancel} className={classnames(styles.content)}>
      <div className={styles.container}>
        <h1>A call record exists for this person. Do you want to create another?</h1>
        <button
          className={classnames(styles.button)}
          onClick={() => {
            onUseExisting && onUseExisting();
          }}
        >
          Use Existing
        </button>
        <button
          className={classnames(styles.button)}
          onClick={() => {
            onCreateNew && onCreateNew();
          }}
        >
          Create New
        </button>
      </div>
    </Modal>
  );
};
const withStyles = styled({
  content: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    marginTop: '-50px',
    marginLeft: '-50px',
    width: '450px',
    backgroundColor: '#333',
    overflow: 'hidden',
    boxShadow: '20px 20px 20px #bbb',
    border: '1px solid #141414',
  },
  container: {
    textAlign: 'center',
    width: '450px',
    background: 'white',
    opacity: 1,
    padding: '40px',
    boxShadow: '0 20px 75px rgba(0, 0, 0, 0.23)',
    color: '#4a4a4a',
    border: '1px solid #4a4a4a',
  },
  button: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#000',
    margin: '5px',
  }),
});
export default withStyles(DuplicateCallModal);
