import React from 'react';
import { styled } from 'react-free-style';
import { connect } from 'react-redux';
import { pickBy } from 'lodash';
import * as elements from '../../styles/elements';

import { Spinner } from '@united-talent-agency/components';

import Wrapper from './wrapper';
import CallList from './call-list';

import { setDesk } from '@united-talent-agency/julius-frontend-store';
import { updateSearch } from '../../api/search';
import { datadogRum } from '@datadog/browser-rum';
import { WIDTH_LIMIT } from '../../support/windowSize';

// Flag used to register what User is active in the application for DataDog.
let DATA_DOG_SET = false;

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      //temporary stint to pass the time of a new call to the call list from the call form
      // (forces an immediate downstream refresh of data for the last 2 minutes )
      lastNewCall: null,
      fetchCounts: {},
      totalCounts: {},
      filtersExpanded: true,
      filtersPerDesk: [],
      prevWidth: 0,
      selectAll: false,
      selectExcept: new Set(),
    };

    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  componentDidMount() {
    // if no configuration is active, DD should be set to true
    if (!datadogRum.getInitConfiguration()) {
      DATA_DOG_SET = true;
    }
    // If screen size is smaller than mediumBreakpoint when loading app, filters should be collapsed initially
    if (window.innerWidth <= WIDTH_LIMIT) {
      this.setState({ filtersExpanded: false });
    }
    this.setState({ prevWidth: window.innerWidth });
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize() {
    const { prevWidth } = this.state;
    // While resizing, if screen size got smaller than mediumBreakpoint, filters should be collapsed
    if (prevWidth > WIDTH_LIMIT && window.innerWidth <= WIDTH_LIMIT) {
      this.setState({ filtersExpanded: false });
    }
    this.setState({ prevWidth: window.innerWidth });
  }

  componentDidUpdate(prevProps) {
    const { user, desk } = this.props;

    if (prevProps.user !== user) {
      if (desk) {
        this.setDesk(desk);
      } else {
        if (user.deskIds && user.deskIds.length) {
          this.setDesk(user.deskIds[0]);
        }
      }
      const { phoneSheetFilter = [] } = user;
      this.setState({ filtersPerDesk: phoneSheetFilter });
    }

    // Flag allows this to fire 1x per user-login, as desired.
    if (user && user.azure_id && !DATA_DOG_SET) {
      datadogRum.setUser({
        id: user.azure_id,
        name: `${user.last_name}, ${user.first_name}`,
        email: user.email,
      });
      datadogRum.startSessionReplayRecording();
      DATA_DOG_SET = true;
    }

    // if (user._id) {
    // TODO: Tracking
    // Identify user
    // userId: user._id,
    // userName: `${user.last_name}, ${user.first_name}`,
    // userEmail: user.email,
    // }
  }

  getAvailableQueryFilters(queryFilters) {
    if (!queryFilters || !queryFilters.length) {
      return;
    }

    const { status } = this.props;
    const statusList = status.map((status) => status.status);
    const filters = queryFilters.split(',');
    const availableFilters = filters.filter((filter) => statusList.includes(filter));
    return availableFilters.length ? availableFilters.join(',') : undefined;
  }

  queryStringToObject(queryString) {
    if (!queryString) {
      return {};
    }

    const URLSearchParamsInstance = new URLSearchParams(queryString);
    return Object.fromEntries(URLSearchParamsInstance.entries());
  }

  queryObjectToString(queryObject) {
    if (!queryObject) {
      return '';
    }

    const URLSearchParamsInstance = new URLSearchParams(queryObject);
    return URLSearchParamsInstance.toString();
  }

  async setDesk(desk) {
    const _desk = desk || {};
    await this.props.dispatch(setDesk(_desk));

    // persist filter when user changes desk
    const { history } = this.props;
    const { filtersPerDesk } = this.state;

    let currentDeskQueries = {};
    if (filtersPerDesk && filtersPerDesk.length) {
      currentDeskQueries = filtersPerDesk.filter(({ deskId }) => deskId === desk._id)[0] ?? {};
    }
    currentDeskQueries.filter = this.getAvailableQueryFilters(currentDeskQueries.filter);
    currentDeskQueries.deskId = desk._id;
    const usedQueries = pickBy(currentDeskQueries, (value) => (value ?? false) === value);
    const queryString = this.queryObjectToString(usedQueries);

    history.push({
      pathname: '/',
      search: queryString ? `?${queryString}` : undefined,
    });
  }

  async navigate(query) {
    const { history, location, desk } = this.props;

    query.filter = this.getAvailableQueryFilters(query.filter);
    query.deskId = query.deskId ?? desk._id;
    const usedQueries = pickBy(query, (value) => (value ?? false) === value);
    const queryString = this.queryObjectToString(usedQueries);

    const filtersPerDesk = await updateSearch({ ...usedQueries, deskId: usedQueries.desk });
    this.setState({ filtersPerDesk });

    if (location.search.substr(1) !== queryString) {
      history.push({
        pathname: '/',
        search: queryString ? `?${queryString}` : undefined,
      });
    }
  }

  render() {
    const { desk, history, isLoading, location, styles, user } = this.props;
    const { fetchCounts, totalCounts, filtersExpanded } = this.state;

    let queries = {};
    if (location) {
      queries = this.queryStringToObject(location.search);
      queries.filter = this.getAvailableQueryFilters(queries.filter);
      queries = pickBy(queries, (value) => (value ?? false) === value);
    }

    if (!user.deskIds || !user.deskIds.length) {
      const hasDeskAssigned = user && user.deskIds && user.deskIds[0] && user.deskIds[0]._id;

      if (!isLoading && !hasDeskAssigned) {
        return (
          <h5 className="m-4">
            No current office groups configured. Please contact the Service Desk at x3900
          </h5>
        );
      }

      return (
        <div className={styles.spinner}>
          <Spinner size={60} />
        </div>
      );
    }

    if (!desk) {
      return <></>;
    }

    const search = {
      query: queries.query,
      filter: queries.filter,
      desk: desk._id,
      favorite: queries.favorite,
    };

    const viewProps = {
      user,
      search,
      history,
      fetchCounts,
      totalCounts,
      filtersExpanded,
      selectAll: this.state.selectAll,
      selectExcept: this.state.selectExcept,
      setFetchCounts: (value) => this.setState({ fetchCounts: value }),
      setTotalCounts: (value) => this.setState({ totalCounts: value }),
      setDesk: (desk) => this.setDesk(desk),
      navigate: (props) => this.navigate(props),
      changeFiltersExpanded: (expanded) => this.setState({ filtersExpanded: expanded }),
      setSelectAll: (newValue) => this.setState({ selectAll: newValue }),
      setSelectExcept: (newValue) => this.setState({ selectExcept: newValue }),
    };

    return (
      <Wrapper {...viewProps} onNewCall={() => this.setState({ lastNewCall: new Date() })}>
        <CallList {...viewProps} newestCall={this.state.lastNewCall} />
      </Wrapper>
    );
  }
}

const withStyles = styled({
  spinner: {
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  link: elements.link,
});

const withState = connect((store) => {
  const { user, desk = {}, isLoading } = store;
  return { user, desk: desk.current, isLoading, status: desk.status || [] };
});

export default withStyles(withState(App));
