import * as React from 'react';
import * as _ from 'lodash';
import { notify } from 'react-notify-toast';
import { connect } from 'react-redux';

import withLaunchDarklyHOC from '../../../components/withLaunchDarklyHOC';
import CallList from './call-list';
import {
  fullRefresh as handleFullRefreshResult,
  incrementalRefresh as handleIncrementalRefreshResult,
  loadMore as handleLoadMoreResult,
} from './helper';
import {
  createShortcuts,
  filterInputEvent,
  keyboard,
  stringifyKey,
} from '../../../support/keyboard';
import { getCommunications } from '../../../api/communications';
import { DEFAULT_CALL_FETCH_LIMIT } from '../../../api/communications';
import client from '../../../graphql/client';
import { buildCallRecordsQuery } from '../../../graphql/queries';

const REFRESH_RATE = (process.env.REACT_APP_REFRESH_RATE_SECONDS || 10) * 1000;

/**
 * @typedef {object} Search
 * @property {string} desk: current desk mongo document id
 * @property {string} [favorite]: 1 or 0 or undefined
 * @property {string} [filter]: comma separated string of status abbreviations
 * @property {string} [query]: current user-search entered into the search bar
 **/
class Wrapper extends React.Component {
  constructor() {
    super();
    this.keyboardListener = filterInputEvent(
      createShortcuts({
        [stringifyKey('up')]: (e) => this.move(e, -1),
        [stringifyKey('j')]: (e) => this.move(e, -1),
        [stringifyKey('down')]: (e) => this.move(e, 1),
        [stringifyKey('h')]: (e) => this.move(e, 1),
        [stringifyKey('v')]: () => this.openProfile(),
      })
    );
    this.state = {
      focused: 0,
      communications: [],
      statusOfLoadingMore: null,
    };
    this.setCommunications = this.setCommunications.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.refreshCalls(this.props.search);
  }

  componentDidUpdate(prevProps) {
    const searchChanged = !_.isEqual(prevProps.search, this.props.search);
    const statusesChanged = !_.isEqual(prevProps.statuses, this.props.statuses);
    const hideFutureCallsChanged = prevProps.hideFutureCalls !== this.props.hideFutureCalls;
    if (searchChanged || hideFutureCallsChanged || statusesChanged) {
      // either changing desk and persisting filter call refreshCalls
      // to prevent consequent refreshCalls, use debounce here
      _.debounce(() => this.refreshCalls(this.props.search), 400)();
    }
  }

  componentDidMount() {
    keyboard.addListener(this.keyboardListener);
  }

  componentWillUnmount() {
    keyboard.removeListener(this.keyboardListener);
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  /**
   * Refresh calls entirely (initial load)
   *
   * @param {Search} search: params for search
   **/
  fullRefresh(search) {
    const { hideFutureCalls, setTotalCounts, setFetchCounts, statuses } = this.props;
    const { favorite, filter, query, desk } = search || {};
    const params = {
      favorite,
      query,
      desk,
      filter,
      hideFutureCalls,
    };
    const promises = statuses
      .filter((status) => status !== '')
      .map((item) =>
        this.getCommunicationsHandler({
          ...params,
          filter: item,
          skip: 0,
          limit: DEFAULT_CALL_FETCH_LIMIT,
        })
      );
    Promise.all(promises)
      .then((results) => {
        return handleFullRefreshResult(
          results,
          statuses,
          setFetchCounts,
          setTotalCounts,
          this.setCommunications
        );
      })
      .then((updatesFrom) => {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(this.refreshCalls.bind(this, search, updatesFrom), REFRESH_RATE);
      })
      .catch(() => {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        notify.show('Failed to load calls', 'error');
        this.timeout = setTimeout(this.refreshCalls.bind(this, search), REFRESH_RATE);
      });
  }

  /**
   * Refresh calls incrementailly (load and update deltas)
   *
   * @param {Search} search: params for search
   * @param {string} lastUpdate: last updated datetime string
   **/
  incrementalRefresh(search, lastUpdate) {
    const { hideFutureCalls, totalCounts, setTotalCounts, fetchCounts, setFetchCounts } =
      this.props;
    const { favorite, filter, query, desk } = search || {};
    const { communications } = this.state;
    const params = {
      favorite,
      query,
      desk,
      filter,
      lastUpdate,
      hideFutureCalls,
    };
    this.getCommunicationsHandler(params)
      .then((result) => {
        return handleIncrementalRefreshResult(
          result,
          fetchCounts,
          totalCounts,
          setFetchCounts,
          setTotalCounts,
          communications,
          this.setCommunications
        );
      })
      .then((updatesFrom) => {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(this.refreshCalls.bind(this, search, updatesFrom), REFRESH_RATE);
      })
      .catch(() => {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(this.refreshCalls.bind(this, search), REFRESH_RATE);
      });
  }

  /**
   * Load more calls per status
   *
   * @param {Search} search: params for search
   * @param {string} status: status of calls to load
   **/
  loadMore(search, status) {
    const { hideFutureCalls, fetchCounts, setFetchCounts } = this.props;
    const { favorite, query, desk } = search || {};
    const params = {
      favorite,
      query,
      desk,
      filter: status,
      hideFutureCalls,
    };
    const { communications } = this.state;
    this.setState({ statusOfLoadingMore: status });
    this.getCommunicationsHandler({ ...params, skip: fetchCounts[status] })
      .then((result) => {
        handleLoadMoreResult(
          result,
          status,
          fetchCounts,
          setFetchCounts,
          communications,
          this.setCommunications
        );
        this.setState({ statusOfLoadingMore: null });
      })
      .catch(() => {
        this.setState({ statusOfLoadingMore: null });
        notify.show('Failed to load more calls', 'error');
      });
  }

  setCommunications(updatedCommunications) {
    this.setState({ communications: updatedCommunications });
  }

  /**
   * Refresh calls
   *
   * @param {Search} search: params for search
   * @param {string} [lastUpdate]: last updated datetime string
   **/
  refreshCalls(search, lastUpdate) {
    if (!lastUpdate && this.timeout) {
      clearTimeout(this.timeout);
    }
    this.fullRefresh(search);
  }

  async getCommunicationsHandler({ favorite, filter, query, desk, lastUpdate, skip, limit }) {
    const { hideFutureCalls, featureGraphql } = this.props;
    const params = {
      favorite,
      query,
      desk,
      filter,
      lastUpdate,
      hideFutureCalls,
    };
    const options = {
      skip,
      limit,
    };

    let response;

    if (featureGraphql) {
      try {
        response = await this.getCommunicationsFromGraphQL(params, options);
      } catch (e) {
        console.error(e);
        response = await getCommunications(params, options);
      }
    } else {
      try {
        response = await getCommunications(params, options);
      } catch (e) {
        console.error(e);
        response = await this.getCommunicationsFromGraphQL(params, options);
      }
    }

    return response;
  }

  async getCommunicationsFromGraphQL(params, options) {
    const { user, statuses } = this.props;
    const { desk, filter, favorite, query, lastUpdate } = params;
    const { limit: take, skip } = options;

    const where = {
      deskId: desk,
      favorite: favorite ? parseInt(favorite) : undefined,
      search: query ? query : undefined,
      statuses: filter ? [filter] : statuses,
      updatedAt: lastUpdate ? lastUpdate : undefined,
    };

    const order = {
      occurrence_date: -1,
    };

    const { data } = await client.query({
      query: buildCallRecordsQuery(user),
      variables: {
        skip,
        take,
        where,
        order,
      },
      fetchPolicy: 'network-only',
    });

    const {
      callRecords: { callRecords, meta },
    } = data;

    const formattedCallRecords = callRecords.map((callRecord) => callRecord);

    const response = { data: formattedCallRecords, meta };

    response.data = response.data.map((callRecord) => {
      const callRecordClone = { ...callRecord };
      callRecordClone.deskId = callRecordClone.desk;

      if (callRecord.recipient?._id) {
        const groupMembership = callRecordClone.recipient?.groupMembership?.length
          ? { ...callRecordClone.recipient.groupMembership[0] }
          : {};

        if (groupMembership.group) {
          groupMembership.group = callRecord.recipient.companies.find(
            (company) => company._id === groupMembership.group
          );

          delete callRecordClone.recipientId.companies;
        }

        callRecordClone.recipientId = {
          ...callRecord.recipient,
          groupMembership,
        };
      }

      callRecordClone.favorite = callRecordClone.favorite ? 1 : undefined;
      callRecordClone.createdAt = callRecordClone.createdAt ? callRecordClone.createdAt : undefined;
      callRecordClone.createdBy = callRecordClone.createdBy ? callRecordClone.createdBy : {};
      callRecordClone.createdBy = callRecordClone.updatedBy ? callRecordClone.updatedBy : {};

      callRecordClone.notes = callRecordClone.notes.map((note) => ({
        ...note,
        _id: note._id ? note._id : undefined,
        createdAt: note.createdAt ? note.createdAt : undefined,
        updatedAt: note.updatedAt ? note.updatedAt : undefined,
        createdBy: note.createdBy ? note.createdBy : undefined,
        updatedBy: note.updatedBy ? note.updatedBy : undefined,
      }));

      delete callRecordClone.desk;
      delete callRecordClone.recipient;
      delete callRecordClone.companyName;

      return callRecordClone;
    });

    return response;
  }

  move(e, pos) {
    e.preventDefault();

    const { callTodos } = this.props;

    this.setState({
      focused: Math.min(Math.max(0, this.state.focused + pos), callTodos.length - 1),
    });
  }

  render() {
    const {
      editCallTodo,
      statuses,
      search,
      totalCounts,
      fetchCounts,
      selectAll,
      setSelectAll,
      selectExcept,
      setSelectExcept,
    } = this.props;
    const { communications = [], statusOfLoadingMore } = this.state;

    const viewProps = {
      statuses,
      communications,
      totalCounts,
      fetchCounts,
      editCallTodo,
      search,
      statusOfLoadingMore,
      selectAll,
      setSelectAll,
      selectExcept,
      setSelectExcept,
      onLoadMore: (status) => {
        this.loadMore(this.props.search, status);
      },
      onEditDone: () => {
        this.refreshCalls(this.props.search);
      },
    };

    return (
      <div>
        <CallList {...viewProps} />
      </div>
    );
  }
}

const withState = connect((store, ownProps) => {
  const { desk = {}, user = {} } = store;
  const searching = ownProps && ownProps.search && ownProps.search.filter;
  let statuses = searching ? ownProps.search.filter.split(',') : desk.status;
  const { calls, preview } = store.callTodo ? store.callTodo : {};
  const { editCallTodo } = store.callForm ? store.callForm : {};
  const { callTodos } = calls || {};

  if (!searching) {
    statuses = desk.status ? statuses.map((s) => s.status) : [];
  }

  const hideFutureCalls =
    store.user && desk.current && desk.current.settings && desk.current.settings.hideFutureCalls;

  return {
    callTodos,
    statuses,
    hideFutureCalls,
    preview,
    editCallTodo,
    statusArray: desk.status,
    user,
  };
});

export default withState(withLaunchDarklyHOC(Wrapper));
