import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { StatusPill, Icons, Tooltip, colors } from '@united-talent-agency/components';

import { patchCommunications } from '../../api/communications';

export const BulkUpdateStatus = ({
  selectAll,
  selectExcept = new Set(),
  statuses,
  filterParams,
  onUpdateApplied = () => {},
  onExit,
}) => {
  const [showBulkStatusUpdateList, setShowBulkStatusUpdateList] = useState(false);
  const wrapperRef = useRef(null);
  const { featureGraphql = false } = useFlags();

  useOutsideAlerter(wrapperRef, setShowBulkStatusUpdateList);

  const handleBulkUpdate = async (status) => {
    try {
      setShowBulkStatusUpdateList(false);
      await patchCommunications({
        filterParams,
        all: selectAll,
        except: Array.from(selectExcept),
        status,
      });
      onUpdateApplied();
    } catch (err) {
      console.error(err);
      onExit && onExit();
    }
  };

  const { ChevronDown } = Icons;
  const someSelected = selectAll || selectExcept.size > 0;
  return (
    <div>
      <StatusSelectorContainer
        style={{
          cursor: `${someSelected ? 'pointer' : 'default'}`,
        }}
      >
        <StatusMenuContainer
          style={!someSelected ? { color: colors.disabledGrey } : {}}
          onClick={() => {
            setShowBulkStatusUpdateList(!showBulkStatusUpdateList);
          }}
        >
          <Tooltip
            text={!someSelected ? 'Select rows to update status on' : 'Set status on selected rows'}
            place="top"
          >
            <div style={{ paddingBottom: '3px' }}>
              Status <ChevronDown color={!someSelected ? colors.disabledGrey : undefined} />
            </div>
          </Tooltip>
          {someSelected && showBulkStatusUpdateList && (
            <StatusList ref={wrapperRef}>
              {/* An overhanging <br /> List of Statuses */}
              {statuses
                .filter((s) => (featureGraphql ? s.status && !s.remove : s.status))
                .map((s, i) => (
                  <BulkStatusUpdateItem key={i} onClick={() => handleBulkUpdate(s.status)}>
                    <StatusPill status={s.status} color={s.color} />
                  </BulkStatusUpdateItem>
                ))}
            </StatusList>
          )}
        </StatusMenuContainer>
      </StatusSelectorContainer>
    </div>
  );
};
export const StatusSelectorContainer = styled.div({
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 400,
});
export const StatusMenuContainer = styled.div({
  zIndex: 1,
  whiteSpace: 'nowrap',
});
export const StatusList = styled.div({
  display: 'block',
  position: 'absolute',
  zIndex: 1,
  backgroundColor: 'white',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
  border: '0.5px solid rgba(0, 0, 0, 0.2)',
  boxSizing: 'border-box',
});
export const BulkStatusUpdateItem = styled.div({
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 5,
  paddingBottom: 5,
  '&:hover': { backgroundColor: 'rgba(41, 161, 219, 0.15)' },
});

/**
 * Hook that alerts clicks outside of the passed ref
 * Reference: https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
 */
function useOutsideAlerter(ref, setShowBulkStatusUpdateList) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowBulkStatusUpdateList(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setShowBulkStatusUpdateList]);
}

export default BulkUpdateStatus;
