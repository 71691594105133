import React from 'react';
import { FilterPanel } from '@united-talent-agency/components';
import { FILTERS } from '../../support/cypressTags';

const FilterPills = ({ status, search, navigate }) => {
  const { filter, favorite } = search;
  const options = { pills: { targetIsArray: true } };
  /**
   * Re-maps the data for consumption
   * @returns {
   *  [
   *    {
   *      text: "pills",
   *      items: [ {status: string, text: string, include: bool}]
   *    }
   *  ]
   * }
   */
  const parseGroups = () => {
    let pills = [];
    if (filter) {
      pills = status.reduce((arr, item) => {
        if (filter.split(',').includes(item.status)) {
          arr.push({
            text: item.name,
            status: item.status,
            include: true,
          });
        }
        return arr;
      }, []);
    }
    if (favorite === '1') {
      pills.push({ status: '1', text: 'Starred', include: true });
    }
    return [{ items: pills, text: 'pills' }];
  };

  /**
   * Receives data from FilterPanel and uses it to update the
   * active filters.
   * @param {
   *  [
   *    {
   *      text: "pills",
   *      items: *[ {status: string, text: string, include: bool}]
   *    }
   *  ]
   * } pills: remaining filter pills
   */
  const onChange = (pills) => {
    const filter_ = pills[0].items.reduce((arr, item) => {
      if (item.text !== 'Starred') {
        arr.push(item.status);
      }
      return arr;
    }, []);

    const favorite = pills[0].items.filter((item) => item.include && item.text === 'Starred').length
      ? '1'
      : null;

    const updatedSearch = {
      ...search,
      favorite,
      filter: filter_.length ? filter_.join(',') : null,
    };

    navigate(updatedSearch);
  };

  return (
    <FilterPanel
      deleteOnly={true}
      filters={options}
      groups={parseGroups()}
      onGroupsChanged={onChange}
      cypressTag={FILTERS.FILTER_PILL}
      all
      legacy
      maxStaticOptions={0}
    />
  );
};

export default FilterPills;
