import React from 'react';
import { styled, helpers } from 'react-free-style';
import { connect } from 'react-redux';

import { elements, icons } from '@united-talent-agency/julius-frontend-components';
import { teleTags } from '../../../../support/telemetry';

import { Icons, StatusPill, Checkbox, colors } from '@united-talent-agency/components';
import { FILTERS } from '../../../../support/cypressTags';
const { ChevronLeftIcon, StarIcon } = Icons;

const Filters = ({
  search,
  navigate,
  capture,
  status: statuses,
  styles,
  filtersExpanded,
  changeFiltersExpanded,
}) => {
  const handleFilterChange = (e) => {
    const { filter } = search;

    let splittedFilter = filter ? filter.split(',') : [];
    const isUnchecked = splittedFilter.indexOf(e.target.value) === -1;
    if (isUnchecked) {
      splittedFilter.push(e.target.value);
    } else {
      splittedFilter = splittedFilter.filter((item) => item !== e.target.value);
    }
    const updatedFilters = {
      ...search,
      filter: splittedFilter.length ? splittedFilter.join(',') : undefined,
    };

    capture(teleTags.changeFilters, updatedFilters);
    navigate(updatedFilters);
  };

  const handleFavoriteChange = () => {
    const { favorite } = search;

    const updatedFilters = { ...search, favorite: favorite === '1' ? undefined : 1 };

    capture(teleTags.changeFilters, updatedFilters);
    navigate(updatedFilters);
  };
  const { filter, favorite } = search;
  const splittedFilter = filter ? filter.split(',') : [];

  return (
    <div className={styles.container} data-cy={FILTERS.FILTERS_LIST}>
      {filtersExpanded && (
        <div className={styles.statusFilter}>
          <span>
            <span style={{ float: 'right' }}>
              <ChevronLeftIcon
                onClick={() => changeFiltersExpanded(false)}
                style={{ cursor: 'pointer', fontSize: 12, fontWeight: 700, color: '#000' }}
              />
            </span>
            <h5 style={{ fontSize: '18px', marginBottom: 20 }}>Filters</h5>
          </span>
          <div className={styles.filterGroup}>
            {statuses?.map((status) => {
              if (status.name !== '' && status.status !== '' && status.status && status.name) {
                const isChecked = splittedFilter.includes(status.status);
                return (
                  <div key={`filter_${status._id}`} data-cy={FILTERS.STATUS_CHECKBOX}>
                    <Checkbox
                      key={`filter_${status._id}`}
                      title={status.name}
                      value={status.status}
                      checked={isChecked}
                      onChange={handleFilterChange}
                      clearedBackground
                    >
                      <div
                        data-cy={FILTERS.SIDE_PANEL_PILL}
                        style={{ marginRight: 10, marginTop: -3, width: 40 }}
                      >
                        <StatusPill status={status.status} color={status.color} />
                      </div>
                    </Checkbox>
                  </div>
                );
              }
              return '';
            })}
          </div>
          <div className={styles.filterGroup}>
            <div data-cy={FILTERS.STAR_CHECKBOX}>
              <Checkbox
                title="Starred"
                value="starred"
                checked={favorite === '1'}
                onChange={handleFavoriteChange}
                clearedBackground
              >
                <div style={{ marginRight: 10, marginTop: -3, width: 40, textAlign: 'center' }}>
                  <StarIcon
                    inline
                    width={14}
                    height={14}
                    stroke={favorite === '1' ? colors.nobel : colors.darkGrey}
                    color={favorite === '1' ? colors.sunshine : colors.white}
                  />
                </div>
              </Checkbox>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const withStyles = styled({
  container: {
    marginTop: 25,
    display: 'flex',
  },
  statusFilter: {
    display: 'flex',
    flexDirection: 'column',
    width: 200,
    paddingLeft: 20,
    paddingRight: 10,
  },
  filterGroup: {
    borderBottom: `1px solid rgb(204, 204, 204)`,
    paddingTop: 15,
    paddingBottom: 15,
  },
  filterButton: helpers.merge(elements.button, elements.actionable, {
    position: 'relative',
    fontSize: '0.6em',
    display: 'flex',
    width: 80,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    '&+&': {
      marginLeft: 0,
    },
  }),
  filterButtonActive: {
    borderColor: '#000',
  },
  clearButton: helpers.merge(elements.button, elements.actionable, {
    flex: 1,
    fontSize: 12,
    fontWeight: 'bold',
    border: 0,
    marginLeft: 10,
    backgroundColor: '#F5F5F5',
  }),
  starActive: icons.starActive,
  squareCrossed: icons.squareCrossed,
  incoming: icons.starOutline,
  archive: icons.archive,
  archiveButton: helpers.merge(
    {
      display: 'flex',
      padding: 10,
      marginLeft: 10,
      alignItems: 'center',
    },
    elements.actionable
  ),
  faOutline: {
    textShadow: '0px 0px 1px black',
  },
  iconSize: {
    fontSize: 14,
  },
});

const withState = connect((state) => {
  const { desk, user } = state;
  return { desk: desk.current, user, status: desk.status || [] };
});

export default withStyles(withState(Filters));
