import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';

import { Branding, Button, Icons } from '@united-talent-agency/components';
import { requestPromise } from '@united-talent-agency/julius-frontend-store';
import CallForm from '../call-form/call-form';
import Filters from './filters/filters';
import Header from '../../shared/header/header';
import {
  createShortcuts,
  filterInputEvent,
  keyboard,
  stringifyKey,
} from '../../../support/keyboard';
import { createCapture } from '../../../support/telemetry';
import { getStatuses } from '../../../api/statuses';
import { getNotes } from '../../../api/people';
import { CALL_FORM } from '../../../support/cypressTags';

import phoneSheetSymbol from '../../../assets/logo/phone_sheet_symbol_200x200.png';
import { saveCall } from '../../../data/call-todo';
import { apiServerUrl } from '../../../support/urls';
import DuplicateCallModal from '../../../components/DuplicateCallModal';
import withLaunchDarklyHOC from '../../../components/withLaunchDarklyHOC';
import * as sizes from '../../../styles/sizes';
import { notify } from 'react-notify-toast';
import FilterPills from '../../../components/filter-pills/filter-pills';
const { ChevronRightIcon } = Icons;

const SHOW_BANNER = process.env.REACT_APP_SHOW_BANNER;

class Wrapper extends Component {
  constructor() {
    super();
    this.state = {
      callTodo: null,
      deskStatuses: [],
    };
    this.keyboardListener = filterInputEvent(
      createShortcuts({
        [stringifyKey('n')]: (e) => {
          e.preventDefault();
          this.createNewCall();
        },
      })
    );
  }

  componentDidMount() {
    keyboard.addListener(this.keyboardListener);
    getStatuses(this.props.desk._id, this.props.featureGraphql).then((deskStatuses) => {
      this.setState({ deskStatuses });
    });
  }

  componentWillUnmount() {
    keyboard.removeListener(this.keyboardListener);
  }

  createNewCall() {
    const status = (this.state.deskStatuses[0] || {}).status;
    const recipientName = '';
    const description = '';
    const contact = { contactType: 'Office Phone' };
    const occurrence_date = new Date().getTime();
    const callTodo = { contact, status, recipientName, description, occurrence_date };
    this.setState({ callTodo });
  }

  /**
   * Handles side-effects when the desk is changed.
   *
   * Closes the call form
   * Calls the function to set a new desk
   * Fetches statuses for the new desk and stores them in local state
   *
   * @param {Object} desk - The new desk.
   * @returns {void}
   */
  handleChangeDesk(desk) {
    // nullifying the callTodo in local state closes the call form
    this.setState({ callTodo: null }, async () => {
      await this.props.setDesk(desk);
      const deskStatuses = desk?._id ? await getStatuses(desk._id, this.props.featureGraphql) : [];
      this.setState({ deskStatuses });
    });
  }

  render() {
    const {
      search,
      navigate,
      children,
      status,
      desk,
      deskIds,
      fetchCounts,
      totalCounts,
      onNewCall,
      filtersExpanded,
      changeFiltersExpanded,
    } = this.props;
    const { pendingCallTodo, dupeId, deskStatuses } = this.state;

    const sumOfFetchCounts = Object.keys(fetchCounts).reduce(
      (prev, cur) => (prev += fetchCounts[cur]),
      0
    );
    const sumOfTotalCounts = Object.keys(totalCounts).reduce(
      (prev, cur) => (prev += totalCounts[cur]),
      0
    );
    return (
      <>
        <GridContainer isCollapsed={filtersExpanded}>
          {/* DESK SELECTOR & BANNER */}
          <BrandingGridCell>
            <Branding
              text="Phonesheet"
              symbol={phoneSheetSymbol}
              showBanner={SHOW_BANNER}
              currentDeskId={desk._id}
              desks={deskIds}
              setDesk={this.handleChangeDesk.bind(this)}
              showDeskSelector
            />
          </BrandingGridCell>
          {/* SEARCH BAR AND HAMBURGER MENU */}
          <SearchBarPlaceholderGridCell />
          <SearchBarGridCell>
            <Header
              search={search}
              noDebounce
              setDesk={this.handleChangeDesk.bind(this)}
              navigate={navigate}
              deskStatuses={deskStatuses}
              onCallAdd={(person) => {
                person.archiveId = person._id;
                person.type = 'Industry Contact';
                person.archive = true;
                delete person._id;

                const phoneContact =
                  person.contacts &&
                  person.contacts.find((c) => {
                    return /Phone/.test(c.contactType);
                  });
                const firstContact = phoneContact || (person.contacts && person.contacts[0]);
                const contact = firstContact || { contactType: 'Office Phone' };

                const callTodo = {
                  status: this.state.deskStatuses[0].status,
                  recipientId: person,
                  contact,
                  contactInfo: contact.contact,
                  description: contact.description,
                  recipientName: person.name,
                  occurrence_date: new Date().getTime(),
                };
                this.setState({ callTodo });
              }}
            />
          </SearchBarGridCell>

          {/* ADD CALL & RESET BUTTONS */}
          <ButtonContainerGridCell>
            <ButtonContainer>
              <FilterContainer>
                <ResultCountContainer>
                  {sumOfTotalCounts} Calls Total |{' '}
                  {sumOfTotalCounts > sumOfFetchCounts ? sumOfFetchCounts : sumOfTotalCounts} Calls
                  Displayed
                </ResultCountContainer>
                <PillsContainer>
                  {!filtersExpanded && (
                    <ChevronRightIcon
                      onClick={() => changeFiltersExpanded(true)}
                      style={{
                        cursor: 'pointer',
                        fontSize: 12,
                        fontWeight: 700,
                        color: '#000',
                        marginRight: 10,
                      }}
                    />
                  )}
                  <ResetButton
                    onClick={() => {
                      delete search.filter;
                      delete search.favorite;
                      this.props.setSelectAll(false);
                      this.props.setSelectExcept(new Set());
                      return navigate ? navigate({ ...search, query: null }) : null;
                    }}
                  >
                    Reset
                  </ResetButton>
                  <Pills>
                    <FilterPills search={search} navigate={navigate} status={status} />
                  </Pills>
                </PillsContainer>
              </FilterContainer>

              <AddCall data-cy={CALL_FORM.ADD_TODO_BUTTON}>
                <Button
                  onClick={() =>
                    this.state.callTodo ? this.setState({ callTodo: null }) : this.createNewCall()
                  }
                  text={this.state.callTodo ? 'Cancel' : '+ Add Call'}
                  primary={false}
                />
              </AddCall>
            </ButtonContainer>
          </ButtonContainerGridCell>
          {/* CALL FORM */}
          <CallFormGridCell>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              {this.state.callTodo && (
                <CallForm
                  statuses={this.state.deskStatuses || []}
                  onSave={(value) => {
                    const desk = this.props.desk;
                    value.deskId = desk._id;
                    const strippedRecipientId = value.recipientId && value.recipientId._id;
                    const dupeCheck = !strippedRecipientId
                      ? Promise.resolve({})
                      : requestPromise(this.props.user, apiServerUrl, '/call_todo', 'GET', null, [
                          { recipientId: strippedRecipientId },
                          { deskId: value.deskId },
                          { '$sort[occurrence_date]': -1 },
                          { $limit: 1 },
                        ]);
                    //check for dupe
                    const pendingCallTodo = value;
                    return dupeCheck.then((response) => {
                      if (response.data && response.data.length > 0) {
                        this.setState({ dupeId: response.data[0]._id, pendingCallTodo });
                      } else {
                        return saveCall(pendingCallTodo).then(() => {
                          onNewCall();
                          // TODO: Tracking
                          // Created new call
                          return this.setState({ callTodo: null });
                        });
                      }
                    });
                  }}
                  callTodo={this.state.callTodo}
                  onChange={(value) => {
                    this.setState({ callTodo: value });
                  }}
                  onCancel={() => {
                    this.setState({ callTodo: null });
                  }}
                />
              )}
            </div>
          </CallFormGridCell>
          {/* FILTERS */}
          <FilterSelectorGridCell>
            <Filters
              search={search}
              navigate={navigate}
              capture={createCapture(this.props.dispatch, this.props.user._id)}
              filtersExpanded={filtersExpanded}
              changeFiltersExpanded={changeFiltersExpanded}
            />
          </FilterSelectorGridCell>
          {/* CALL LIST */}
          <CallListGridCell>{children}</CallListGridCell>
        </GridContainer>
        <DuplicateCallModal
          isOpen={!!dupeId}
          onCancel={() => {
            this.setState({ dupeId: null, pendingCallTodo: null });
          }}
          onCreateNew={() => {
            return saveCall(pendingCallTodo).then(() => {
              onNewCall();
              return this.setState({ dupeId: null, pendingCallTodo: null, callTodo: null });
            });
          }}
          onUseExisting={async () => {
            pendingCallTodo._id = dupeId;

            let body;
            try {
              ({ body } = await getNotes(pendingCallTodo.recipientId._id));
            } catch (e) {
              console.error(e);
              body = { notes: [] };
            }

            pendingCallTodo.notes = [...pendingCallTodo.notes, ...body.notes];

            try {
              const result = await saveCall(pendingCallTodo);
              this.setState({ dupeId: null, pendingCallTodo: null, callTodo: null });
              return result;
            } catch (error) {
              notify.show('Failed to save call', 'error');
            }
          }}
        />
      </>
    );
  }
}

const GridContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: (props) => {
    return props.isCollapsed ? `250px minmax(400px, auto)` : `5% minmax(400px, auto)`;
  },
  gridTemplateRows: 'min-content min-content 50px minmax(0, max-content) auto',
  [`@media ${sizes.mediumBreakpoint}`]: {
    gridTemplateColumns: (props) => {
      return props.isCollapsed ? `250px minmax(400px, auto)` : `45px minmax(400px, auto)`;
    },
  },
  [`@media ${sizes.mobileBreakpoint}`]: {
    gridTemplateColumns: (props) => {
      return props.isCollapsed ? `250px minmax(400px, auto)` : `15px minmax(400px, auto)`;
    },
  },
});

const BrandingGridCell = styled.div({ gridColumnStart: 1, gridColumnEnd: 'span 2' });

const SearchBarPlaceholderGridCell = styled.div({
  gridColumnStart: 1,
  gridColumnEnd: 2,
  backgroundColor: '#FFF',
});

const SearchBarGridCell = styled.div({
  gridColumnStart: 2,
  gridColumnEnd: 3,
  paddingRight: '5%',
  backgroundColor: '#FFF',
  [`@media ${sizes.mediumBreakpoint}`]: {
    paddingRight: 45,
  },
  [`@media ${sizes.mobileBreakpoint}`]: {
    paddingRight: 15,
  },
});

const ButtonContainerGridCell = styled.div({
  gridRowStart: 3,
  gridRowEnd: 4,
  gridColumnStart: 2,
  gridColumnEnd: 3,
  marginRight: '5%',
  marginTop: 10,
  alignSelf: 'center',
  [`@media ${sizes.mediumBreakpoint}`]: {
    marginRight: 45,
  },
  [`@media ${sizes.mobileBreakpoint}`]: {
    marginRight: 15,
  },
});

const ButtonContainer = styled.div({
  display: 'inline-grid',
  gridTemplateColumns: 'min-content min-content auto auto',
  gridTemplateAreas: '"expand reset filters addCall"',
  alignItems: 'center',
  width: '100%',
});

const FilterContainer = styled.div({
  gridArea: 'filters',
});

const ResultCountContainer = styled.div({
  fontSize: 12,
  fontWeight: 600,
});

const PillsContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  height: 26,
  marginBottom: 8,
});

const ResetButton = styled.div({
  cursor: 'pointer',
  paddingRight: '15px',
  fontSize: 12,
});

const Pills = styled.div({
  justifySelf: 'flex-start',
});

const AddCall = styled.div({
  gridArea: 'addCall',
  cursor: 'pointer',
  justifySelf: 'flex-end',
});

const CallFormGridCell = styled.div({
  gridRowStart: 4,
  gridRowEnd: 5,
  gridColumnStart: 2,
  gridColumnEnd: 3,
  marginRight: '5%',
  [`@media ${sizes.mediumBreakpoint}`]: {
    marginRight: 45,
  },
  [`@media ${sizes.mobileBreakpoint}`]: {
    marginRight: 15,
  },
});

const FilterSelectorGridCell = styled.div({
  gridRowStart: 3,
  gridRowEnd: 6,
  gridColumnStart: 1,
  gridColumnEnd: 2,
  marginLeft: '25px',
});

const CallListGridCell = styled.div({
  gridRowStart: 5,
  gridRowEnd: 6,
  gridColumnStart: 2,
  gridColumnEnd: 3,
  marginRight: '5%',
  [`@media ${sizes.mediumBreakpoint}`]: {
    marginRight: 45,
  },
  [`@media ${sizes.mobileBreakpoint}`]: {
    marginRight: 15,
  },
});

const withState = connect((state) => {
  const { desk, user } = state;
  const { deskIds } = user;
  const { editCallTodo, showForm } = state.callForm;

  return {
    desk: desk.current,
    status: desk.status,
    deskIds,
    editCallTodo,
    showForm,
  };
});

export default withState(withLaunchDarklyHOC(Wrapper));
