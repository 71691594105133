import { gql } from '@apollo/client';

const UPDATE_STATUS = gql`
  mutation updateStatus($input: [DeskStatusInput!]!) {
    updateStatus(input: $input) {
      message
      success
    }
  }
`;

const DELETE_STATUS = gql`
  mutation deleteStatus($statuses: [DeskStatusInput!]!) {
    deleteStatus(statuses: $statuses) {
      message
      success
    }
  }
`;

export { UPDATE_STATUS, DELETE_STATUS };
