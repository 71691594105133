import React from 'react';
import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { colors } from '@united-talent-agency/components';
import { HEADER } from '../../support/cypressTags';

export const HeaderMenu = ({
  menuOptions = [],
  MenuMainIcon = () => 'MENU',
  defaultOpenState = false,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpenState);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setIsOpen);

  const renderIcon = ({ MenuOptionIcon, props }) => {
    return (
      <MenuItemIconContainer>
        <MenuOptionIcon inline width={15} height={15} {...props} />
      </MenuItemIconContainer>
    );
  };

  return (
    <MenuColumn>
      <MenuButton data-cy={HEADER.MENU_DROPDOWN}>
        <MenuMainIcon
          id="menu-main-icon"
          onClick={() => setIsOpen(!isOpen)}
          width={35}
          height={35}
        />
      </MenuButton>
      {isOpen && (
        <MenuContainer ref={wrapperRef}>
          {menuOptions.map((option, index) => {
            return (
              <MenuItem
                key={index}
                data-cy={HEADER.MENU_ITEM}
                id="header-menu-option"
                onClick={() => {
                  option.onSelect && option.onSelect();
                  setIsOpen(false);
                }}
              >
                {option.MenuOptionIcon && renderIcon(option)}
                {option.name}
              </MenuItem>
            );
          })}
        </MenuContainer>
      )}
    </MenuColumn>
  );
};

// Hook that alerts clicks outside of the passed ref
function useOutsideAlerter(ref, setIsOpen) {
  useEffect(() => {
    // Close menu if clicked on outside of element
    function handleClickOutside(event) {
      const clickedMainIcon = Object.values(event.target).some(
        (clickedElement) => clickedElement.id === 'menu-main-icon'
      );
      if (ref.current && !ref.current.contains(event.target) && !clickedMainIcon) {
        setIsOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setIsOpen]);
}

const MenuColumn = styled.div({
  display: 'flex',
  marginLeft: 'auto',
  marginRight: 0,
  flexDirection: 'column',
  alignItems: 'flex-end',
});

const MenuContainer = styled.div({
  top: 95,
  position: 'absolute',
  border: '0.5px solid rgba(0, 0, 0, 0.2)',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
  boxSizing: 'border-box',
  zIndex: 2,
  marginRight: 3,
  maxWidth: '50%',
  width: 310,
  background: 'white',
});

const MenuButton = styled.div({
  cursor: 'pointer',
  flex: 0.1,
});

const MenuItem = styled.div({
  display: 'flex',
  cursor: 'pointer',
  fontSize: 12,
  padding: '7px 15px',
  ':first-child': {
    paddingTop: 14,
  },
  ':last-child': {
    paddingBottom: 14,
  },
  '&:hover': {
    backgroundColor: colors.fadedLinkBlue,
  },
});

const MenuItemIconContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginRight: 10,
});
